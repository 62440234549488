import React, { useEffect, useState } from "react";
import NameControl from "../components/common/NameControl";
import OverViewPayment from "../components/overview/OverViewPayment";
import { useSelector } from "react-redux";
// eslint-disable-next-line
import OverviewData from "../components/overview/OverviewData";
import SetLimit from "../components/modals/SetLimit";
import FundBalance from "../components/modals/FundBalance";
import EnterAmount from "../components/modals/EnterAmount";
import VirtualAccount from "../components/modals/VirtualAccount";
import CardDetails from "../components/modals/CardDetails";
import SelectPayment from "../components/modals/SelectPayment";
import TransactionOtp from "../components/modals/TransactionOtp";
import TransactionSuccessful from "../components/modals/TransactionSuccessful";
import Success from "../components/modals/Success";
import AvailableBalance from "../components/modals/AvailableBalance";
import BalanceModal from "../components/balance/modal/BalanceModal";
import ApiWallet from "../components/modals/ApiWallet";
import ApiWalletConfirm from "../components/modals/ApiWalletConfirm";
import Successful from "../components/modals/Successful";
import TransferOptions from "../components/customers/modals/TransferOptions";
import TransferPortfolio from "../components/customers/modals/TransferPortfolio";
import PortfolioCompanyAmount from "../components/customers/modals/PortfolioCompanyAmount";
import PortFolioConfirm from "../components/customers/modals/PortFolioConfirm";
import TransferToBank from "../components/customers/modals/TransferToBank";
import Beneficiary from "../components/customers/modals/Beneficiary";
import BeneficiaryValue from "../components/customers/modals/BeneficiaryValue";
import DollarAmount from "../components/customers/modals/DollarAmount";
import DollarVirtual from "../components/customers/modals/DollarVirtual";
import DollarSuccess from "../components/customers/modals/DollarSuccess";
import PinOtp from "../components/modals/PinOtp";
import TransferBetweenCurrency from "../components/customers/modals/TransferBetweenCurrency";
import NigerTransferPortfolioAmount from "../components/customers/modals/NigerTransferPortfolioAmount";
import axios from "axios";
import PortfolioSuccess from "../components/customers/modals/PortfolioSuccess";
import ComingSoonPop from "../components/modals/ComingSoonPop";
import TableLoader from "../components/common/TableLoader";
import ApiPayoutBalance from "../components/modals/ApiPayoutBalance";
import VirtualConfirm from "../components/modals/VirtualConfirm";
import ApiWalletOtp from "../components/modals/ApiWalletOtp";

function OverView({
  balanceData,
  setBalanceData,
  graphData,
  graphCurrency,
  setGraphCurrency,
}) {
  useEffect(() => {
    getBanks();
    getBalance();
    getCollection();
    getPayout();
    getEncKey();
    // eslint-disable-next-line
  }, []);
  const currentUser = JSON.parse(localStorage.getItem("portco")) || {};

  const [btnActive, setBtnActive] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Payments");
  const [isSetLimit, setIsSetLimit] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isVertual, setIsVertual] = useState(false);
  const [isCardDetails, setIsCardDetails] = useState(false);
  const [isCardMethod, setIsCardMethod] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAmount, setIsAmount] = useState(false);
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayout, setIsPayout] = useState(false);
  const [isAmountLoading, SetIsAmountLoading] = useState(false);
  const [isApiWallet, setIsApiWallet] = useState(false);
  const [isApiOtp, setIsApiOtp] = useState(false);
  const [apiWallet, setApiWallet] = useState("");
  const [apiInputData, setApiInputData] = useState({});
  const [isApiPayout, setIsApiPayout] = useState(false);
  const [amountInput, setAmountInput] = useState("");
  const [banks, setBank] = useState([]);
  const [LinkedCardData, setLinkedCardData] = useState([]);
  const [nubanTempData, setNubanTempData] = useState({});
  const [nubanData, setNubanData] = useState([]);
  const [nubTemInput, setNubTempInput] = useState({});
  const [nubanId, setNubanId] = useState("");
  const [isPinOtp, setIsPinOtp] = useState(false);
  const [isVirtualConfirm, setIsVirtualConfirm] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [isCardsLoading, setIsCardLoading] = useState(false);
  const [isPinLoading, setIsPinLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [payoutCurrency, setPayoutCurrency] = useState("");
  const [transactionId, setTransactionid] = useState("");
  const [livePublicEncKey, setLivePublicEncKey] = useState("");
  const [beneficiary, setBeneficiary] = useState(false);
  const [inputCardDetails, setInputCardDetails] = useState({
    name: "",
    card_number: "",
    card_date: "",
    card_cvv: "",
  });
  const [isPayoutInput, setIsPayoutInput] = useState({
    currency: "",
    amount: "",
  });
  const [isPortfolioInput, setIsPortfolioInput] = useState({
    currency: "",
    amount: "",
    amount2: "",
    desc: "",
  });
  const [transferBankInput, setTransferBankInput] = useState({
    balance: "",
    amount: "",
    currency: "NGN",
    bank_code: "",
    narration: "",
    account_number: "",
    beneficiary_name: "",
    bank_name: "",
  });
  const [isApiWalletConfirm, setIsApiWalletConfirm] = useState(false);
  const [balanceModal, setBalanceModal] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isTransferOption, setIsTransferOption] = useState(false);
  const [isTransferPortfolio, setIsTransferPortfolio] = useState(false);
  const [isTransferBank, setIsTransferBank] = useState(false);
  const [walletOverviewData, setWalletOveerViewData] = useState({});
  const [usdWalletBalance, setUsdWaalletBalance] = useState("");
  const [isTransferPortfolioAmount, setIsTransferPortfolioAmount] = useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [isNigerBankTransfer, setIsNigerBankTransfer] = useState(false);
  const [isSelectBeneficiary, setIsSelectBeneficiary] = useState(false);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [apiData, setApiData] = useState({});
  const [isApiFundLoading, setIsApiFundLoading] = useState(false);
  const [isDollarAmount, setIsDollarAmount] = useState(false);
  const [isDollarVirtual, setIsDollarVirtual] = useState(false);
  const [isDollarSuccess, setIsDollarSuccess] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [payoutToken, setToken] = useState("");
  const [payoutId, setPayoutId] = useState("");
  const [payoutResult, setPayoutResult] = useState({});
  const [isTransferCurrency, setIsTransferCurrency] = useState(false);
  const [collectionData, setCollectionData] = useState([]);
  const [payoutData, setPayoutData] = useState([]);
  // const [balanceData,setBalanceData] = useState([])
  const [otpInput, setOtpInput] = useState("");
  const [isNGNPortfolioAmount, setNGNPortfolioAmount] = useState(false);

  const data = [
    { name: "Jan", Total: 1200 },
    { name: "Feb", Total: 2100 },
    { name: "Mar", Total: 800 },
    { name: "Apr", Total: 1600 },
    { name: "May", Total: 900 },
    { name: "June", Total: 1700 },
    { name: "July", Total: 1700 },
    { name: "August", Total: 1700 },
  ];

  const getBalance = async () => {
    try {
      // const headers = {
      //   "api_key": currentUser.user.merchantKey,
      //   "sweetapi": currentUser.user.merchantCode
      // }
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/balances?category=collection",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          // 'Authorization': 'Bearer pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          api_key: currentUser.user.merchantKey,
          Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
        },
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("balance", newValue);
      setBalanceData(newValue);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.message || "unable to fetch data";
    }
  };

  const getBanks = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/banks",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso'
          api_key: currentUser?.user?.merchantKey,
        },
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("bank", newValue);
      setBank(newValue);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.message || "unable to fetch data";
    }
  };
  const getLinkedCards = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-payment-card";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data;
      setLinkedCardData(data);
    } catch (err) {
      console.log(err);
    }
  };

  // const getGraph = async ()=>{
  //   try{

  //     const headers = {
  //       "api_key": currentUser?.user?.merchantKey,
  //       "sweetapi": currentUser?.user?.merchantCode
  //     }

  //     const data ={
  //       currency: "NGN"
  //     }

  //     const url = 'https://1esi7ghq4i.execute-api.us-east-2.amazonaws.com/production/transaction-graph';
  //     let res = await axios.post(url,{...data},{
  //       headers:headers
  //     })
  //     console.log(res.data.data);
  //     const newValue = res?.data?.data
  //     // setCollectionData(newValue);

  //     // toast.success(res.data.message);
  //   }catch(err){

  //     console.log(err);
  //     const errorMessage = err?.response?.data?.data?.message || "Server error"

  //   }
  // }

  //collection
  const getCollection = async () => {
    try {
      let data = JSON.stringify({
        category: "collection", // payout //required
        currency: "NGN", // USD  // required
        limit: 5, // optional
        // date_begin: "2023-11-01", // optional
        // date_end: "2023-12-31", // optional
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/transactions",
        headers: {
          // 'sweetapi': 'CUS_RFVcKsbBySc6pxmF0COxR',
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          sweetapi: currentUser?.user?.merchantCode,
          api_key: currentUser?.user?.merchantKey,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("collection", newValue);
      setCollectionData(newValue);
    } catch (error) {
      console.log(error);
    }
  };

  //payout
  const getPayout = async () => {
    try {
      let data = JSON.stringify({
        category: "payout", // payout //required
        currency: "NGN", // USD  // required
        limit: 5, // optional
        // date_begin: "2023-11-01", // optional
        // date_end: "2023-12-31", // optional
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/transactions",
        headers: {
          // 'sweetapi': 'CUS_RFVcKsbBySc6pxmF0COxR',
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          sweetapi: currentUser?.user?.merchantCode,
          api_key: currentUser?.user?.merchantKey,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("payout", newValue);
      setPayoutData(newValue);
    } catch (error) {
      console.log(error);
    }
  };
  const getEncKey = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-encryption-key";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data?.livePublicEncKey;
      setLivePublicEncKey(data);
    } catch (err) {
      console.log(err);
    }
  };

  const iniateCharge = async (e) => {
    let amount;
    SetIsAmountLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
      };

      amount = {
        amount: `${amountInput * 100}`,
        url: "https://checkout.paywithflick.co/pages/",
      };
      console.log("amount Payload", amount);
      const url =
        "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge";
      let res = await axios.post(url, amount, {
        headers: headers,
      });

      const transId = res?.data?.data?.transactionId || res.data?.transactionId;
      setTransactionid(transId);
      SetIsAmountLoading(false);
      setIsPaymentMethod(true);
      //  console.log(res.data);

      //  toast.success(res.data?.message);
      //  const id = res.data?.data?.transactionId
    } catch (err) {
      SetIsAmountLoading(false);
      console.log(err);
    }
  };
  const handleApiOtherBalance = async (currency) => {
    setIsApiFundLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
      };
      const data = {
        amount: `${amountInput * 100}`,
        currency: currency,
        bank_code: "apiwallet",
      };

      // Make an API call to fetch accountName using bankCode and accountNumber.
      console.log("api payLoad:", data);
      setApiInputData(data);
      const url =
        "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
      let res = await axios.post(url, { ...data }, { headers: headers });
      console.log("response Data: ", res.data);
      setApiData(res.data);
      setIsApiFundLoading(false);
      setIsApiOtp(true);

      // setIsLoading(false)
    } catch (err) {
      setIsApiFundLoading(false);
      console.log(err);
    }
  };

  // eslint-disable-next-line
  const handleBtnActive = () => {
    setBtnActive(!btnActive);
  };
  // eslint-disable-next-line
  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };
  const toggleSetLimitModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const balanceNav = () => {
    setIsBalanceOpen(!isBalanceOpen);
  };
  const confirmLimit = () => {
    console.log("limit value : ");
  };

  return (
    <div className="main-inner">
      <NameControl
        btn
        image
        name={currentUser?.user?.name}
        desc="Here’s how Portco is performing"
      />
      <OverViewPayment
        collectionData={collectionData}
        payoutData={payoutData}
        balanceNav={balanceNav}
        data={data}
        balanceData={balanceData}
        setGraphCurrency={setGraphCurrency}
        setBalanceModal={setBalanceModal}
        walletOverviewData={walletOverviewData}
        setWalletOveerViewData={setWalletOveerViewData}
      />
      {isBalanceOpen && (
        <AvailableBalance
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsTransferOption={setIsTransferOption}
          setIsTransferBank={setIsTransferBank}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsModalOpen={setIsModalOpen}
          setIsAmount={setIsAmount}
          setIsDollarAmount={setIsDollarAmount}
          title={
            balanceModal?.currency === "NGN"
              ? "Nigerian Naira"
              : balanceModal?.currency === "USD"
              ? "United States Dollar"
              : balanceModal?.currency === "KES"
              ? "Kenyan Shilling"
              : balanceModal?.currency
          }
          amount={balanceModal?.payout_balance}
          icon={balanceModal?.currency}
          balanceModal={balanceModal}
        />
      )}
      {isDollarAmount && (
        <DollarAmount
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsDollarVirtual={setIsDollarVirtual}
        />
      )}
      {isAmount && (
        <EnterAmount
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          iniateCharge={iniateCharge}
          isAmountLoading={isAmountLoading}
          setAmountInput={setAmountInput}
          isApiWallet = {isApiWallet}
          setApiWallet={setApiWallet}
        />
      )}
      {isDollarVirtual && (
        <DollarVirtual
          setIsDollarVirtual={setIsDollarVirtual}
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsDollarSuccess={setIsDollarSuccess}
        />
      )}
      {isDollarSuccess && (
        <DollarSuccess
          setIsDollarSuccess={setIsDollarSuccess}
          setIsDollarVirtual={setIsDollarVirtual}
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
        />
      )}
      {isTransferOption && (
        <TransferOptions
          setIsTransferOption={setIsTransferOption}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransferBank={setIsTransferBank}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
        />
      )}
      {isTransferCurrency && (
        <TransferBetweenCurrency
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          isPortfolioInput={isPortfolioInput}
        />
      )}
      {isNigerBankTransfer && (
        <TransferToBank
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsTransferOption={setIsTransferOption}
          transferBankInput={transferBankInput}
          setTransferBankInput={setTransferBankInput}
          setIsSelectBeneficiary={setIsSelectBeneficiary}
          setIsBeneficiary={setIsBeneficiary}
          setIsBalanceOpen={setIsBalanceOpen}
          payoutId={payoutId}
          // banks={banks}
          balanceData={balanceData}
          setPayoutId={setPayoutId}
          setPayoutResult={setPayoutResult}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          beneficiary={beneficiary}
          setIsPortfolioSuccess={setIsPortfolioSuccess}
        />
      )}
      {isSelectBeneficiary && (
        <Beneficiary setIsSelectBeneficiary={setIsSelectBeneficiary} />
      )}
      {isBeneficiary && (
        <BeneficiaryValue
          setIsBeneficiary={setIsBeneficiary}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setBeneficiary={setBeneficiary}
        />
      )}
      {isTransferPortfolio && (
        <TransferPortfolio
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
        />
      )}
      {isTransferPortfolioAmount && (
        <PortfolioCompanyAmount
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          setIsBeneficiary={setIsBeneficiary}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          isPortfolioInput={isPortfolioInput}
        />
      )}
      {isNGNPortfolioAmount && (
        <NigerTransferPortfolioAmount
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          setIsBeneficiary={setIsBeneficiary}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          isPortfolioInput={isPortfolioInput}
        />
      )}
      {isConfirmPortfolio && (
        <PortFolioConfirm
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setToken={setToken}
          payoutId={payoutId}
          payoutToken={payoutToken}
          transferBankInput={transferBankInput}
          payoutResult={payoutResult}
        />
      )}
      {isPortfolioSuccess && (
        <PortfolioSuccess
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          transferBankInput={transferBankInput}
          payoutResult={payoutResult}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsTransactionSuccess={setIsTransactionSuccess}
        />
      )}
      {isModalOpen && (
        <SetLimit
          open={true}
          onClose={toggleSetLimitModal}
          setIsModalOpen={setIsModalOpen}
          title="Set low limit"
          desc="Enter a minimum threshold to be notified"
          confirmLimit={confirmLimit}
          setIsSuccessful={setIsSuccessful}
        />
      )}
      {isSuccessful && (
        <Successful
          setIsSuccessful={setIsSuccessful}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isPaymentMethod && (
        <FundBalance
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsVertual={setIsVertual}
          setIsCardMethod={setIsCardMethod}
          setIsPayout={setIsPayout}
          transactionId={transactionId}
          getLinkedCards={getLinkedCards}
          setNubanData={setNubanData}
          setIsComingSoon={setIsComingSoon}
          payoutCurrency={payoutCurrency}
          apiWallet={apiWallet}
          setApiWallet={setApiWallet}
          setIsApiPayout={setIsApiPayout}
          amountInput={amountInput}
          setNubanTempData={setNubanTempData}
          setIsVirtualConfirm={setIsVirtualConfirm}
          setNubanId={setNubanId}
          setNubTempInput={setNubTempInput}
        />
      )}
      {isVertual && (
        <VirtualAccount
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsVertual={setIsVertual}
          setIsSuccess={setIsSuccess}
          transactionId={transactionId}
          nubanData={nubanData}
          apiWallet={apiWallet}
          nubanTempData={nubanTempData}
          setIsVirtualConfirm={setIsVirtualConfirm}
          amountInput={amountInput}
        />
      )}
      {isVirtualConfirm && (
        <VirtualConfirm
          setIsVirtualConfirm={setIsVirtualConfirm}
          isVirtualConfirm={isVirtualConfirm}
          transactionId={nubanId}
          nubTemInput={nubTemInput}
          getBalance={getBalance}
        />
      )}
      {isCardMethod && (
        <SelectPayment
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsCardMethod={setIsCardMethod}
          setIsPin={setIsPin}
          setIsOtp={setIsOtp}
          setIsCardDetails={setIsCardDetails}
          setIsBalanceOpen={setIsBalanceOpen}
          LinkedCardData ={LinkedCardData}
          setIsPinOtp={setIsPinOtp}
          transactionId={transactionId}
          apiWallet = {apiWallet}
        />
      )}
       {isApiPayout && (
        <ApiPayoutBalance
          setIsApiPayout={setIsApiPayout}
          balanceData={balanceData}
          handleApiOtherBalance={handleApiOtherBalance}
          isApiFundLoading={isApiFundLoading}
        />
      )}
      {isPayout && (
        <ApiWallet
          setIsPayout={setIsPayout}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayoutInput={setIsPayoutInput}
          setIsAmount={setIsAmount}
          isPayoutInput={isPayoutInput}
          setIsApiWalletConfirm={setIsApiWalletConfirm}
        />
      )}
      {isApiWalletConfirm && (
        <ApiWalletConfirm
          setIsApiWalletConfirm={setIsApiWalletConfirm}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayout={setIsPayout}
          setIsAmount={setIsAmount}
          setIsSuccess={setIsSuccess}
        />
      )}
         {isApiOtp && (
        <ApiWalletOtp
          setIsApiOtp={setIsApiOtp}
          setIsApiPayout={setIsApiPayout}
          apiData={apiData}
          apiInputData={apiInputData}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          getBalance={getBalance}
        />
      )}
      {isCardDetails && (
        <CardDetails
          setIsCardDetails={setIsCardDetails}
          setIsCardMethod={setIsCardMethod}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          transactionId={transactionId}
          livePublicEncKey={livePublicEncKey}
          setIsOtp={setIsOtp}
          setIsPinOtp={setIsPinOtp}
          inputCardDetails={inputCardDetails}
          setInputCardDetails={setInputCardDetails}
          setIsCardLoading={setIsCardLoading}
          apiWallet={apiWallet}
        />
      )}
      {isOtp && (
        <TransactionOtp
          setIsOtp={setIsOtp}
          setIsPin={setIsPin}
          setIsCardMethod={setIsCardMethod}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsSuccess={setIsSuccess}
          setIsPinLoading={setIsPinLoading}
          transactionId={transactionId}
          setIsPinOtp={setIsPinOtp}
        />
      )}
      {isPinOtp && (
        <PinOtp
          setOtpInput={setOtpInput}
          setIsPin={setIsPin}
          setIsOtp={setIsOtp}
          setIsPinOtp={setIsPinOtp}
          setIsOtpLoading={setIsOtpLoading}
          setIsCardMethod={setIsCardMethod}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsAmount={setIsAmount}
          transactionId={transactionId}
          getBalance={getBalance}
        />
      )}
      {isTransactionSuccess && (
        <TransactionSuccessful
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsPin={setIsPin}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsCardMethod={setIsCardMethod}
          setIsCardDetails={setIsCardDetails}
          setIsOtp={setIsOtp}
          setIsAmount={setIsAmount}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsBalanceOpen={setIsBalanceOpen}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          transferBankInput={transferBankInput}
          payoutResult={payoutResult}
          setIsPinOtp={setIsPinOtp}
          inputCardDetails={inputCardDetails}
          amountInput={amountInput}
          transactionId={transactionId}
          getBalance={getBalance}
        />
      )}
      {isSuccess && (
        <Success
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayout={setIsPayout}
          setIsApiWalletConfirm={setIsApiWalletConfirm}
          setIsCardMethod={setIsCardMethod}
          setIsCardDetails={setIsCardDetails}
          setIsOtp={setIsOtp}
          setIsVertual={setIsVertual}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsSuccess={setIsSuccess}
          setIsBalanceOpen={setIsBalanceOpen}
        />
      )}
      {isComingSoon && <ComingSoonPop setIsComingSoon={setIsComingSoon} />}

      {(isAmountLoading || isCardsLoading || isOtpLoading || isPinLoading) && (
        <TableLoader msg="Processing..." />
      )}
    </div>
  );
}

export default OverView;
