import React from 'react'

function NigerTransferPortfolioAmount({setNGNPortfolioAmount,setIsTransferPortfolio,setIsTransferOption,setIsBalanceOpen,setIsPortfolioInput,isPortfolioInput,setIsConfirmPortfolio,setIsBeneficiary,setIsNigerBankTransfer}) {
    const handleCancel = ()=> {
        setNGNPortfolioAmount(false);
        setIsTransferPortfolio(false)
        setIsTransferOption(false);
        setIsBalanceOpen(false)

    }

    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setIsPortfolioInput((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        setIsConfirmPortfolio(true)
    }
  return (
    <div className="overlay">
        <div className="enter-amount-modal">
        <div className="modal-wrapper">

            <div>
            <img style={{cursor:"pointer"}} onClick={setNGNPortfolioAmount.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
            <p className="enter-amount">Transfer to Portfolio Company</p>
            
            <p className="enter-an-amount">Here is an overview of your transaction</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="api_wallet_input">
                    
                    
                    
                    <div className='api_select_container'>
                        <div className='api_wallet_select_input'>
                            <img src="/images/NGN.svg" alt="ng"/>    
                            <select 
                            className='api_select_val' 
                            name='currency'
                            value={isPortfolioInput.currency} 
                            onChange={handleInputChange}>
                                <option value="USD">USD</option>
                                <option value="NGN">NGN</option>
                                <option value="KES">KES</option>
                            </select>
                        </div>
                        <input  name='amount' className='api_input_amountss'  placeholder="5.00" disabled/>
                    </div>    
                    

                    <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group`} 
                        >
                        <input 
                        placeholder='AJIBADE AYOMIDE DAVID' 
                        type='text' 
                        name="amount2" 
                        className='input_cardNumber' 
                        onChange={handleInputChange} 
                        />
                         
                        </div>
                        <p className='card_label' style={{color:"#8C8F97"}}>Account name</p>
                    </div>
                    <input className='portfolio_amount_input' placeholder='Description (optional)' name='desc' onChange={handleInputChange} style={{marginTop:"16px"}}/>
                </div>
                <div className="modal-btn">
                    <button onClick={handleCancel} className="btn">
                        Cancel
                    </button>
                    <button  className="btn">
                        Confirm
                    </button>

                 </div>
            </form>
            </div>
        </div>
    </div>
  )
}

export default NigerTransferPortfolioAmount
