import React, { useEffect, useState } from 'react'
import '../../modals/Modal.scss'
import styled from 'styled-components'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader, CircleLoader } from 'react-spinners';



function TransferToBank({balanceData,transferBankInput,setTransferBankInput,banks,payoutId,setPayoutId,setPayoutResult,setIsNigerBankTransfer,setIsTransferOption,setIsSelectBeneficiary,setIsBeneficiary,setIsBalanceOpen,setIsConfirmPortfolio,beneficiary}) {
    // const { currentUser } = useSelector((state) => state?.user);
    let currentUser = JSON.parse(localStorage.getItem("portco"));
    const [page,setPage] = useState("")
    const [shouldFetchAccountName, setShouldFetchAccountName] = useState(false);
    const [selectBank,setSelectBank] = useState('')
    const [isLoading,setIsLoading] = useState(false);
    const [isPayout,setIsPayout] = useState(false);
    const [details, setDetails] = useState([]);
    // const [inputDetails, setInputDetails] = useState({
    //     bank_name: details.BankName,
    //     account_number: details.Nuban,
    //     bank_code: details.BankCode,
    //     full_name: details.fullName
    //   });
    
    //   const [transferBankInput, setTransferBankInput] = useState({
    //     balance: "",
    //     amount: "",
    //     currency: "NGN",
    //     bank_code:  '',
    //     narration: "",
    //     account_number: '',
    //     beneficiary_name: '',
    //     bank_name:  '',
    //   });

    useEffect(() => {
        if (shouldFetchAccountName) {
          fetchAccountName();
        }
        // eslint-disable-next-line
      }, [shouldFetchAccountName]);

      useEffect(() => {
        fetchAccountDetails();
        // eslint-disable-next-line
      }, []);
      
    const handleBankChange = (e)=>{
        setSelectBank(e.target.value)
    }
    const handleCancel = ()=>{
        setIsNigerBankTransfer(false);
        setIsTransferOption(false);
        setIsBalanceOpen(false)
    }

    const  handleInputChange = (event)=>{
        const { name, value } = event.target; 
        setTransferBankInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // if (name === 'account_number' && value.length === 10) {
        //     setIsLoading(true);
        //    fetchAccountName();
        // } else {
        // setShouldFetchAccountName(false);
        // }
    }
    const onBankChange = (selectedBankCode) => {
        const selectedBank = details.find((detail) => detail.BankCode === selectedBankCode);
        setTransferBankInput((prevFormData) => ({
            ...prevFormData,
            bank_code: selectedBankCode,
            account_number: selectedBank.Nuban,
            beneficiary_name: selectedBank.fullName,
        }));
    };

const fetchAccountDetails = async () => {
    try {

        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-single-portco-accounts',
        headers: { 
            // 'api_key': 'pk-U2FsdGVkX19IM3otV2MnXjY576YBd2lbgHmeEUv3NQNtGS6lnOPPtjxSATdWRRsdt27PbnzjK0KRidZR75PKq4RuZe2mfK3o0eWgE25szuC2XqpEagumYRsgJGWht3DU', 
            // 'sweetapi': 'CUS_ybiA47kmM4jDQ_Sl4D0x-'
            api_key: currentUser?.user?.merchantKey,
            sweetapi: currentUser?.user?.merchantCode,
            "Authorization": `Bearer ${currentUser?.user?.merchantKey}`,
        }
    };
    const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

    const newValue = response?.data?.data;
    const bankDetails = {
        balance: "",
        amount: "",
        currency: "NGN",
        bank_code: newValue[0].BankCode ||  '',
        narration: "",
        account_number: newValue[0].Nuban || '',
        beneficiary_name: newValue[0].fullName || '',
        bank_name: newValue[0].BankName ||  '',
    }
    console.log("details", newValue);
    setDetails(newValue);
    // setTransferBankInput(bankDetails)
}    catch(error) {
        console.log(error);
    }
}

    const fetchAccountName = async () => {
        try {

            let data = ({
                account_number:transferBankInput.account_number,
                bank_code:transferBankInput.bank_code
              });
            // const headers = {
            //     api_key: currentUser?.user?.merchantKey,
            //     sweetapi: currentUser?.user?.merchantCode,
            // };
            // const data = {
            //     account_number:transferBankInput.account_number,
            //     bank_code:transferBankInput.bank_code
            // }

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/name-enquiry",
                headers: {
                  api_key: currentUser?.user?.merchantKey,
                  sweetapi: currentUser?.user?.merchantCode,
                  "Authorization": `Bearer ${currentUser?.user?.merchantKey}`,
                  "Content-Type": "application/json",
                },
                data: data,
              };

            console.log("payload: ",data)
        //   // Make an API call to fetch accountName using bankCode and accountNumber.
        //     const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/name-enquiry";
        //     let res = await axios.post(url,{...data},{ headers: headers});
        //     console.log("bank detail",res?.data?.data) ;
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            console.log("bank detail",response?.data?.data);
          // Update the accountName in your state with the fetched data.
            
            setTransferBankInput((prevFormData) => ({
                ...prevFormData,
                beneficiary_name: response?.data?.data?.account_name,
            }));
           
            setIsLoading(false)
            
        } catch (error) {
          // Handle errors, e.g., show an error message.
          setIsLoading(false)
          console.error('Error fetching accountName:', error);
        }
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsPayout(true);
        try{
            const headers = {
                'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
            };
            const data = {
                amount: `${Number(transferBankInput.amount) * 100}`,
                currency: transferBankInput.currency,
                bank_code: transferBankInput.bank_code,
                narration: transferBankInput.narration,
                account_number: transferBankInput.account_number,
                beneficiary_name:transferBankInput.beneficiary_name,
            }
           
          // Make an API call to fetch accountName using bankCode and accountNumber.
          console.log("payLoad:", data)
            const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
            let res = await axios.post(url,{...data},{ headers: headers});
           
            toast.success(res?.data?.message,{position: toast.POSITION.TOP_LEFT})
            setPayoutId(res.data?.Id)
            setPayoutResult(res?.data)
            setIsPayout(false);
            setIsConfirmPortfolio(true)
            
        }catch(err){
            setIsPayout(false);
            console.log(err)
            const errValue = err?.response?.data?.message || "Unable to confirm transfer"
            toast.error(errValue, {position: toast.POSITION.TOP_LEFT})
        }
        
    }
 
  return (
    <div className="overlay">
        {console.log('result', transferBankInput)}
        <div className="enter-amount-modal">
        <div className="modal-wrapper">

            <div>
            <img style={{cursor:"pointer"}} onClick={setIsNigerBankTransfer.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title" style={{display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:"row"}}>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <p className="enter-amount">Transfer to Bank Account</p>
                    
                    <p className="enter-an-amount">Here is an overview of your transaction</p>
                </div>
                <div>
                <img src="/images/view-limit.svg" alt="limit"/>    
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="api_wallet_input">
                    {/*  */}

                    <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
                        >
                         <select 
                            className='balance_select' 
                            style={{background:"transparent"}}
                            name='balance'
                            value={transferBankInput.balance} 
                            onChange={handleInputChange}>
                               <option value="NGN">{`${balanceData[0]?.currency} ${Number(balanceData[0]?.payout_balance)/100}`}</option>
                        </select>
                        </div>
                        <p className='card_label'>Choose balance to transfer from</p>
                    </div>

                    {/*  */}
                  
                    {/* <div className='api_current_rate' style={{marginBottom:"16px"}}>
                        <div className='api_current_rate_left'>
                            <img src="/images/currentRate.svg" alt='rate' />
                            <p className='current_rate_text'>CURRENT RATE</p>
                        </div>
                        <div className='api_current_rate_right'>
                            <span>$1</span>
                            <span> = </span>
                            <span>N998.51</span>
                        </div>
                    </div> */}
                    
                    <div className='api_select_container' style={{marginTop:"16px"}}>
                        <div className='api_wallet_select_input'>
                            <img src="/images/NGN.svg" alt="ng"/>    
                            <select 
                            className='api_select_val' 
                            name='currency'
                            value={transferBankInput.currency} 
                            onChange={handleInputChange}>
                                <option value="NGN">NGN</option>
                                {/* <option value="USD">USD</option>
                                <option value="KES">KES</option> */}
                            </select>
                        </div>
                        <input  name='amount' className='api_input_amountss'  placeholder="5.00" onChange={handleInputChange}/>
                    </div> 
                    <p className='select_exist' style={{cursor:"pointer"}} onClick={setIsBeneficiary.bind(this,true)}>Or select existing beneficiary  <img style={{display:"inline",marginLeft:"8px"}} src='/images/customers/selectBeneficiary.svg' alt='ben'/></p>    
{/* 
                    <select 
                            className='bank_select' 
                            name='bank_code'
                            style={{marginTop:"16px",background:"transparent"}}
                            onChange={handleInputChange}>
                            <option value="" selected disabled>Bank name</option>
                        {
                            details.map((detail,index)=>(
                                <option key={index} value={detail?.BankCode}>{detail?.BankName}</option>
                            ))
                        }
                        </select> */}
                       <select
                        className='bank_select'
                        name='bank_code'
                        value={transferBankInput.bank_code}
                        onChange={(e) => {
                            handleInputChange(e);
                            onBankChange(e.target.value);
                        }}>
                        <option value="" disabled>Select Bank</option>
                        {details.map((detail, index) => (
                            <option key={index} value={detail.BankCode}>
                                {detail.BankName}
                            </option>
                        ))}
                    </select>

                    <input
                        className='portfolio_amount_input'
                        value={transferBankInput.account_number}
                        placeholder='Account number'
                        name='account_number'
                        onChange={handleInputChange}
                        style={{ marginTop: "16px" }}
                        readOnly
                    />

                        <div className='account_loader'>
                            {
                                isLoading ?
                                <div className='loader_overlay'>  
                                <CircleLoader
                                    color="#259792"
                                    size={15}
                                    />
                                </div>:
                                <input value={transferBankInput.beneficiary_name} className='portfolio_amount_input' placeholder='Account name' name='beneficiary_name' onChange={handleInputChange} style={{marginTop:"16px"}} readOnly/>
                            }
                        </div>
                    {/* {
                        !beneficiary ?
                        <select 
                            className='bank_select' 
                            name='bank'
                            style={{marginTop:"16px",background:"transparent"}}
                            value={selectBank} 
                            onChange={handleBankChange}>
                            <option value="" className='select_placeholdeer' style={{color:"#8C8F97"}}>bank name</option>
                            <option value="GTBank">GTBank</option>
                            <option value="ZENITH">ZENITH</option>
                            <option value="ACCESS">ACCESS</option>
                        </select>
                        :
                        <div className='input_banks_container'>
                            <div 
                            // onClick={() => changeActive("card_num")}
                            className={`input_group`} 
                            >
                            
                            <select 
                            className='bank_top_select' 
                            name='bank'
                            style={{marginTop:"16px",background:"transparent"}}
                            value="GTBank" 
                            onChange={handleBankChange}>
                            <option value="" className='select_placeholdeer' style={{color:"#8C8F97"}}>bank name</option>
                            <option value="GTBank">GTBank</option>
                            <option value="ZENITH">ZENITH</option>
                            <option value="ACCESS">ACCESS</option>
                        </select>
                            
                            </div>
                            <p className='card_label' style={{color:"#8C8F97"}}>Account name</p>
                         </div>
                    }    */}

                    {/* <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group`} 
                        >
                        <input 
                        placeholder='USD 50.00|' 
                        type='text' 
                        name="amount2" 
                        className='input_cardNumber' 
                        onChange={handleInputChange} 
                        />
                         
                        </div>
                        <p className='card_label' style={{color:"#8C8F97"}}>Enter amount</p>
                    </div> */}

                    {/* {
                        !beneficiary ?
                        <input className='portfolio_amount_input' placeholder='Account number' name='account_number' onChange={handleInputChange} style={{marginTop:"16px"}}/>
                        :
                        <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group`} 
                        >
                        <input 
                        placeholder='USD 50.00|' 
                        type='text' 
                        name="acc_num" 
                        className='input_cardNumber' 
                        
                        value="0130086553"
                        />
                         
                        </div>
                        <p className='card_label' style={{color:"#8C8F97"}}>Account number</p>
                         </div>
                    } */}
                    
                    {/* if beneficairy and account numbeer exist in data base */}
                    {/* {beneficiary && 
                         <div className='input_banks_container'>
                            <div 
                            // onClick={() => changeActive("card_num")}
                            className={`input_group`} 
                            >
                            <input 
                            placeholder='USD 50.00|' 
                            type='text' 
                            name="amount2" 
                            className='input_cardNumber' 
                            
                            value="AjIBADE AYOMIDE DAVID"
                            />
                            
                            </div>
                            <p className='card_label' style={{color:"#8C8F97"}}>Account name</p>
                         </div>

                    } */}
                    <input className='portfolio_amount_input' placeholder='Description (optional)' name='narration' onChange={handleInputChange} style={{marginTop:"16px"}}/>
                </div>
                <div className="modal-btn">
                    <button onClick={handleCancel} className="btn">
                        Cancel
                    </button>
                    {
                        !isPayout ?
                    <button  className="btn" onClick={handleSubmit}>   
                    Confirm transfer
                    </button>:
                    <button  className="btn" >
                        <BeatLoader
                            color="#FFF"
                            cssOverride={{}}
                        />
                    </button>
                    }
                 </div>
            </form>
            </div>
        </div>
        <ToastContainer style={{ fontSize: "14px", zIndex: 1000 }} />
    </div>
  )
}

export default TransferToBank
