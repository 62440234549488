import React, { useRef, useState, useEffect, createRef } from "react";
import "../components/common/Pages.scss";
import TableHeaderSearch from "../components/common/TableHeaderSearch";
import { copyToClipboard } from "../components/utilities/clipboardUtils";
import Status from "../components/common/Status";
import { useNavigate } from "react-router-dom";
import CollectionModal from "../components/payment/CollectionModal";
import PayoutOverviewModal from "../components/payment/PayoutOverviewModal";
import axios from "axios";
import { format } from "timeago.js";
import { toast } from "react-toastify";
function Payment() {
  useEffect(() => {
    getCollection();
    getBalance();
    getPayout();
    getBanks();
    // eslint-disable-next-line
  }, []);
  
  const currentUser = JSON.parse(localStorage.getItem("portco")) || {};
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Inflow");
  const [selectedValue, setSelectedValue] = useState("NGN");
  const [isCollection, setIsCollection] = useState(false);
  const [isPayout, setIsPayout] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [collectionData, setCollectionData] = useState([]);
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [payoutData, setPayoutData] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isTransferPortfolioAmount, setIsTransferPortfolioAmount] = useState(false);
  const [banks, setBank] = useState([]);
  const [payoutId, setPayoutId] = useState("");
  const [payoutToken, setToken] = useState("");
  const [payoutResult, setPayoutResult] = useState({});
  const [isPayoutLoading, setIsPayoutLoading] = useState(false);

  const [transferBankInput, setTransferBankInput] = useState({
    balance: "",
    amount: "",
    currency: "NGN",
    bank_code: "",
    narration: "",
    account_number: "",
    beneficiary_name: "",
  });

  const inputRef = useRef(null);
  const lineRefs = React.useRef([]);
  lineRefs.current = collectionData.map((_, i) => lineRefs.current[i] ?? createRef());
  lineRefs.current = payoutData.map((_, i) => lineRefs.current[i] ?? createRef());

  const handleTableRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsPayout(true);
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const toggleModal = () => {
    setIsCollection(!isCollection);
  };
  const toggleInflow = () => {
    setIsPayout(!isPayout);
  };
  const handleOutnflowReciept = (item) => {
    setSelectedRowData(item);
    setIsPayout(true);
  };
  const togglePayoutModal = () => {
    setIsPayout(!isPayout);
  };
  const toggleTransfer = () => {
    setIsOpenPopup(!isOpenPopup);
  };


  const getCollection = async () => {
    setIsCollectionLoading(true);
    try {
      let data = JSON.stringify({
        category: "collection", // payout //required
        currency: "NGN", // USD  // required
        limit: 5, // optional
        // date_begin: "2023-11-01", // optional
        // date_end: "2023-12-31", // optional
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/transactions",
        headers: {
          // 'sweetapi': 'CUS_RFVcKsbBySc6pxmF0COxR',
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          sweetapi: currentUser?.user?.merchantCode,
          api_key: currentUser?.user?.merchantKey,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("collection", newValue);
      setCollectionData(newValue);

      setIsCollectionLoading(false);
    } catch (err) {
      setIsCollectionLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };

  const getBalance = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/balances?category=collection",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          // 'Authorization': 'Bearer pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          api_key: currentUser?.user?.merchantKey,
          Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
        },
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("balance", newValue);
      setBalanceData(newValue);
    } catch (error) {
      console.log(error);
    }
  };

  const getPayout = async () => {
    setIsPayoutLoading(true);
    try {
      let data = JSON.stringify({
        category: "payout", // payout //required
        currency: "NGN", // USD  // required
        limit: 5, // optional
        // date_begin: "2023-11-01", // optional
        // date_end: "2023-12-31", // optional
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/transactions",
        headers: {
          // 'sweetapi': 'CUS_RFVcKsbBySc6pxmF0COxR',
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          api_key: currentUser?.user?.merchantKey,
          sweetapi: currentUser?.user?.merchantCode,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("payout", newValue);
      setPayoutData(newValue);
      setIsPayoutLoading(false);
    } catch (err) {
      setIsPayoutLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };

  const getBanks = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/banks",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso'
          api_key: currentUser?.user?.merchantKey,
        },
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("bank", newValue);
      setBank(newValue);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.message || "unable to fetch data";
    }
  };

  return (
    <div className="main-inner">
      <div className="payment_top">
        <div className="payment_top_left">
          <div className="payment_top_content">
            <p className="payment_top_titlee">Payment</p>
            <p className="payment_top_desc">
              Transaction history is shown here
            </p>
          </div>

          <div className="currencies_select">
            <div className="currencies_select_content">
              <img
                src={`/images/${
                  selectedValue === "NGN"
                    ? "NGN"
                    : selectedValue === "USD"
                    ? "USD"
                    : ""
                }.svg`}
                alt="usd"
              />
              <select
                className="select_input_val"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
        </div>
        {/* top-right */}
        <div className="data-nav-btn">
          <span
            onClick={() => handleTabClick("Inflow")}
            className={`dataItembtn ${activeTab === "Inflow" ? "active" : ""}`}
          >
            Inflow
          </span>
          <span
            onClick={() => handleTabClick("Outflow")}
            className={`dataItembtn ${activeTab === "Outflow" ? "active" : ""}`}
          >
            Outflow{" "}
          </span>
        </div>
      </div>

      {/*  */}
      {activeTab === "Inflow" && (
        <div className="balance-overview">
          <table className="tbl">
            <tr>
              <td colSpan="7" className="tbl-heading">
                <TableHeaderSearch
                  title={activeTab}
                  placeholder="Search by name or date..."
                />
              </td>
            </tr>
            {Array.isArray(collectionData) && collectionData.length > 0 && (
              <tr>
                <th className="th-text">Amount</th>
                <th className="th-text">Transaction Reference</th>
                <th className="th-text">Channel</th>
                <th className="th-text">Date Initiated</th>
                <th className="th-text">Date Sent</th>
                <th className="th-text">Status</th>
              </tr>
            )}

            {Array.isArray(collectionData) && collectionData.length > 0 && collectionData.map((item, index) => (
                <tr>
                  <td className="td-text">{`${item?.currency_settled} ${item?.total_amount / 100}`}</td>
                  <td className="td-text">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        ref={lineRefs.current[index]}
                        className="link_table"
                        value={item?.transactionid}
                        hidden
                        readOnly
                      />
                      <h1 className="td-text">
                        {item.transactionid.slice(0, -4) + "..."}
                      </h1>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/copy-key-icon.svg"
                        }
                        alt="ic"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(lineRefs.current[index]);
                        }}
                      />
                    </div>
                  </td>
                  <td className="td-text">{item?.channel}</td>
                  <td className="td-text">{format(item?.dated)}</td>
                  <td className="td-text">{item?.sent ? item?.sent : "N/A"}</td>
                  <td className="td-text">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",}} >
                      {
                        ((item.status === "success") || (item.status === "Success")) &&
                        <Status icon="/images/success.svg" mgs="Successful " />
                      }
                      {
                      item.status === "pending" &&
                        <Status
                          icon="/images/Dot.svg"
                          mgs="pending"
                          bg="#FFF8F0"
                          border="#F79009"
                          color="#F79009"
                        />
                      }
                      {
                      item.status === "failed" &&
                        <Status
                          icon="/images/x.svg"
                          mgs="pending"
                          bg="#FEF3F2"
                          border="#FECDCA"
                          color="#B42318"
                        />
                      }
                      <img
                        // onClick={toggleModal}
                        src="/images/eye.svg"
                        alt="eye"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            {
            !isCollectionLoading && collectionData.length <= 0 && (
              <tr>
                <td colSpan={7} style={{ width: "100%" }}>
                  <div className="empty_tbl">
                    <img src="/images/emptyPage.svg" alt="emptuy" />
                    <h3 className="num_trans">No Transactions Yet</h3>
                    <p className="num_desc">It is necessary to perform transactions on your Flick account</p>
                  </div>
                </td>
              </tr>
            )}
          </table>
        </div>
      )}

      {
      activeTab === "Outflow" && (
        <div className="balance-overview">
          <table className="tbl">
            <tr>
              <td colSpan="7" className="tbl-heading">
                <TableHeaderSearch
                  title={activeTab}
                  placeholder="Search by name or date..."
                />
              </td>
            </tr>

            {
            Array.isArray(payoutData) && payoutData.length > 0 && (
              <tr>
                <th className="th-text">Amount</th>
                <th className="th-text">Recipient </th>
                <th className="th-text">Date Initiated</th>
                <th className="th-text">Date Sent</th>
                <th className="th-text">Status</th>
              </tr>
            )}
            {/*   
            <tr onClick={() => handleOutnflowReciept({amount: 'NGN 840,000.00',name: 'Mide Ajibade',time1: '2 hours ago',time2: '2 hours ago'})}>
                <td className='td-text'>{`${item?.currency_settled} ${item?.total_amount/100}`}</td>
                <td className='td-text'>{item.beneficiaryname}</td>
                <td className='td-text'>{format(item?.dated)}</td>
                <td className='td-text'>{item?.sent ? item?.sent: "N/A"}</td>
                <td className='td-text'>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        <Status 
                        icon="/images/success.svg"
                        mgs="Successful"
                        />
                        <img onClick={toggleInflow} src='/images/eye.svg' alt='eye' />
                    </div>
                </td>    
            </tr> */}
            {
            Array.isArray(payoutData) && payoutData.length > 0 && payoutData.map((item, index) => (
                <tr key={item.transactionid} onClick={() => handleTableRowClick(item)}>
                  <td className="td-text">{`${item?.currency_settled} ${item?.total_amount / 100}`}</td>
                  <td className="td-text">{item.beneficiaryname}</td>
                  <td className="td-text">{format(item?.dated)}</td>
                  <td className="td-text">{item?.sent ? item?.sent : "N/A"}</td>
                  <td className="td-text">
                    <div
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <Status icon="/images/success.svg" mgs="Successful" />
                      <img
                        // onClick={toggleInflow}
                        src="/images/eye.svg"
                        alt="eye"
                      />
                    </div>
                  </td>
                </tr>
              ))
              }

            {!isPayoutLoading && payoutData.length <= 0 && (
              <tr>
                <td colSpan={7} style={{ width: "100%" }}>
                  <div className="empty_tbl">
                    <img src="/images/emptyPage.svg" alt="empty" />
                    <h3 className="num_trans">No Payout Yet</h3>
                    <p className="num_desc">It is necessary to perform transactions on your Flick account</p>
                  </div>
                </td>
              </tr>
            )}
          </table>
        </div>
      )}
      {isCollection && (
        <CollectionModal toggleModal setIsCollection={setIsCollection} />
      )}

      {isPayout && (
        <PayoutOverviewModal
          toggleInflow={toggleInflow}
          data={selectedRowData}
        />
      )}
    </div>
  );
}

export default Payment;
