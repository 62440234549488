import React from "react";

function ComingSoonPop({ setIsComingSoon }) {
  return (
    <div className="overlay">
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div className="input-amount">
            <h1 className="enter-amount">Coming Soon!!!</h1>
          </div>

          <div className="modal-btn">
            <button onClick={setIsComingSoon.bind(this, false)} className="btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonPop;
