import React, { useState, useRef } from "react";
import "./Modal.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

function TransactionOtp({ setIsOtp, setIsTransactionSuccess,transactionId ,setIsPinOtp,setIsPinLoading}) {
  const [otp, setOtp] = useState(['', '', '', '']); // Initialize an array with empty strings for each digit
  const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  // const { currentUser } = useSelector((state) => state?.user);
  let currentUser = JSON.parse(localStorage.getItem("portco"));
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e, index) => {
    let value = e.target.value;

    if (value.length > 1) {
        value = value.charAt(0);
    }

    if (/^[0-9]*$/.test(value) && index >= 0 && index < otpInputs.length) {
      otp[index] = value;
      setOtp([...otp]);

      // Auto focus on the next input field if available
      if (index < otpInputs.length - 1 && value !== '') {
        otpInputs[index + 1].current.focus();
      }
    }
  };


  const handleSubmit = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    setIsPinLoading(true)
    const enteredOTP = otp.join('');
    
    const pinData ={
      transactionId: transactionId,
      pin: enteredOTP
    }
    
   
    try{
      const headers = {  
        Authorization:`Bearer ${currentUser?.user?.merchantKey}`
      }
      const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/verify-pin"
      let res = await axios.post(url,{...pinData},{
        headers:headers
      }) 
      console.log("pin:",res.data)
      setIsPinLoading(false)
    
      if(res?.data?.status==="success" && res.data.authorizationMode ==="otp"){
        setIsLoading(false);
        setIsPinOtp(true)
       
      }else if(res.data.status==="success" && (res?.data?.authorizationMode !=="otp" || res.data.authorizationMode !=="pin")){
        setIsLoading(false);
        setIsTransactionSuccess(true)
      }if(res?.data?.status ==="success" && res?.data?.authorizationMode === "3ds"){
         if((res?.data?.authMessage).toLowerCase()==="3ds url" && res.data.responseUr){
          setIsLoading(false);
          window.location.href = res?.data?.responseUr;
          
        }else if((res.data.authMessage).toLowerCase()==="3ds" && !res.data.responseUr){
          setIsLoading(false);
          toast.error("unable to complete payment");
        }else{
          setIsLoading(false);
          toast.error("unable to complete payement");
        }
      }
      else{
        setIsLoading(false);
        setIsOtp(false)
      } 
      setIsLoading(false);
    }catch(err){
      setIsLoading(false);
      setIsPinLoading(false)
      console.log(err)
      const errorMessage = err?.response?.data?.message || err.message
      toast.error(errorMessage);
    }
    
  }
  
  return (
    <div className="overlay">
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div className="otp-icon">
            <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              onClick={() => setIsOtp(false)}
            />
            <img
              onClick={() => setIsOtp(false)}
              src={process.env.PUBLIC_URL + "/images/x-close.svg"}
              alt="icon"
            />
          </div>

          <div className="modal-title">
            <p className="enter-amount">Enter Card PIN</p>

            <p className="enter-an-amount">
            Please enter your card 4-digit pin to authorise this payment
            </p>
          </div>

          <div className="input-otp">
            <div className="input-field">
            {otp.map((digit, index) => (
                <input
                key={index}
                type='text'
                maxLength="1"
                className='otp'
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                ref={otpInputs[index]}
                autoFocus={index === 0}
                />
             ))}
            </div>
            <p className="code">
              Didn't get a code?{" "}
              <span className="click">
                Click to resend.
              </span>
            </p>
          </div>

          <div className="modal-btn">
            <button
              onClick={() => setIsOtp(false)}
              className="first-btn"
            >
              Back
            </button>
            {
               isLoading ? 
               <button   className="btn">  
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
               </button> :
                <button onClick={handleSubmit}  className="btn">  
                  Confirm
                </button>

             }
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionOtp;
