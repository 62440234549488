import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function PortFolioConfirm({setIsPortfolioSuccess,setToken,payoutId,payoutToken,transferBankInput,payoutResult,setNGNPortfolioAmount,setIsConfirmPortfolio,setIsTransferPortfolioAmount,setIsTransferPortfolio,setIsTransferOption,setIsBalanceOpen,setIsTransferCurrency,setIsNigerBankTransfer}) {
    // const { currentUser } = useSelector((state) => state?.user);
    let currentUser = JSON.parse(localStorage.getItem("portco"));

    const [isLoading,setIsLoading] = useState(false);


    const handleCancel = ()=>{
    setIsConfirmPortfolio(false);
    setIsNigerBankTransfer(false)
    setNGNPortfolioAmount(false)
    setIsTransferPortfolioAmount(false);
    setIsTransferPortfolio(false)
    setIsTransferOption(false);
    setIsBalanceOpen(false);
    // setIsTransferCurrency(false)
    
 }

 const  handleInputChange = (e)=>{
    setToken(e.target.value);
 }
 const handleBox =(e)=>{
   e.stopPropagation();
   setIsConfirmPortfolio(false)
 }
 const handleSuccess = async (e)=>{
    e.stopPropagation();
    setIsLoading(true);
    try{

      const headers = {
        'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
      };

      const data = {
        Id:payoutId,
        token: payoutToken,   
      }
  
      // Make an API call to fetch accountName using bankCode and accountNumber.
      const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/complete-payout";

      let res = await axios.post(url,{...data},{ headers: headers});
      console.log("Confirm transaction",res?.data)
      setIsLoading(false);
      toast.success(res?.data.message,{position: toast.POSITION.TOP_LEFT})

      setIsPortfolioSuccess(true);

      }catch(err){
        setIsLoading(false);
        console.log(err);
        toast.error("unable to confirm transfer",{ position: toast.POSITION.TOP_LEFT})
      }
  }

  const resendToken = async (e)=>{
    e.stopPropagation();
    try{

      const headers = {
        'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
      };

      const data = {
        Id:payoutId  
      }
  
      // Make an API call to fetch accountName using bankCode and accountNumber.
      console.log("payload-data",data)
      const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/resend-token";
      let res = await axios.post(url,{...data},{ headers: headers});

      toast.success('code resend successfully',{ position: toast.POSITION.TOP_LEFT})

      }catch(err){
        console.log(err);
        toast.success('unable to resend code', {position: toast.POSITION.TOP_LEFT})
      }
  }


  return (
    <div className="overlay">
        <div className="enter-amount-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-wrapper">

            <div>
            <img style={{cursor:"pointer"}} onClick={handleBox} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
            <p className="enter-amount">Confirm Transfer</p>
            
            {/* <p className="enter-an-amount">You are about to transfer  <span style={{color:"#151F32",fontWeight:"700"}}>NGN {transferBankInput?.amount}</span> to <span style={{color:"#151F32",fontWeight:"700"}}> {transferBankInput?.beneficiary_name} </span> 
            <br/>
            Recipient would receive  <span style={{color:"#151F32",fontWeight:"700"}}> NGN {transferBankInput?.amount}</span> within 2 days
            </p> */}
            <p className="enter-an-amount">Sending  <span style={{color:"#151F32",fontWeight:"700"}}> NGN {transferBankInput?.amount} </span> to <span style={{color:"#151F32",fontWeight:"700"}}>  {transferBankInput?.beneficiary_name} </span> {` ${payoutResult?.bank_name} - ${transferBankInput?.account_number}`}.</p>

            </div>

            <div className="api_wallet_input">
                <p className="portfolio_enter_amount" >Enter the confirmation code sent to <span style={{color:"#151F32",fontWeight:"500"}}>{payoutResult?.email}</span> and <span style={{color:"#151F32",fontWeight:"500"}}> {`+${payoutResult?.phone}`}</span>  </p>
                <input  name='amount' className='api_input_amounts'  placeholder="Enter your confirmation code for verification" onChange={handleInputChange} required/>

                <p className="enter-an-amount" style={{textAlign:"center",marginTop:"16px"}} onClick={resendToken}><img src="/images/resend.svg" alt='resend' style={{display:"inline"}}/> <span className='api_resend'> Resend code</span> </p>
                <div className="modal-btn">
                <button onClick={handleCancel} className="btn">
                    Cancel
                </button>
                {
                  !isLoading?
                  <button onClick={handleSuccess} className="btn">
                  Confirm transfer
                  </button>:
                  <div className='loader_overlay'>  
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                      />
                  </div>
                }
            </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PortFolioConfirm
