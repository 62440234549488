import React from "react";
import './Modal.scss'

function VirtualAccount({setIsVertual,setIsSuccess,nubanData,apiWallet,nubanTempData,setIsVirtualConfirm,amountInput}) {
//   if (!open) return null;

  const handleConfirm = ()=>{
    setIsVirtualConfirm(true);
  }
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper scrollNuban">

        <div> 
        <img style={{cursor:"pointer"}} onClick={setIsVertual.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          {
            apiWallet==="api" ?
            <p className="enter-amount">Temporary Virtual Nuban</p>:
            <p className="enter-amount">Fund via Virtual Account</p>
          }
          
          <p className="enter-an-amount">Bank transfer using the provided information below.</p>
        </div>

        <div className="scrollNuban">
        {
         (!apiWallet && nubanData?.length > 0) && nubanData.map((item,index)=>(

            <div className="account-details ">
                <div className="number-name">
                    <div className="number">
                        <p className="title" >Account number</p>
                        <p className="sub-title">{item?.account_number} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                    </div>

                    <div className="name">
                        <p className="title" style={{textAlign:"end"}}>Account name</p>
                        <p className="sub-title">{item?.account_name} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                    </div>
                </div>

                <div className="number-name">
                    <div className="number">
                        <p className="title">Bank name</p>
                        <p className="sub-title">{item?.bank_name} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                    </div>

                    <div className="name">
                        <p className="title">Account type</p>
                        <p className="sub-title">Current <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                    </div>
                </div>

                

            </div>
          ))
        }
          </div>

        {
         apiWallet==="api" && 
          <div className="account-details">
              <div className="number-name">
                  <div className="number">
                      <p className="title" >Account number</p>
                      <p className="sub-title">{nubanTempData?.account_number} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                  </div>

                  <div className="name">
                      <p className="title" style={{textAlign:"end"}}>Account name</p>
                      <p className="sub-title">{nubanTempData?.account_name} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                  </div>
              </div>

              <div className="number-name">
                  <div className="number">
                      <p className="title">Bank name</p>
                      <p className="sub-title">{nubanTempData?.bank} </p>
                  </div>

                  <div className="name">
                      <p className="title">Account type</p>
                      <p className="sub-title">Current </p>
                  </div>
              </div>

              <div className="number-name">
                  <div className="number">
                      <p className="title">Amount</p>
                      <p className="sub-title">{`₦${amountInput}`}</p>
                  </div>
              </div>

          </div>
          
        }
        {
          (nubanData?.length < 0 || !nubanTempData) &&
          <div className="account-details">
             <p className="title">No Available Nuban</p>
          </div>
        }
        {
          apiWallet==="api" && 
          <p className="title">Nuban will expire in 30 mins</p> 
        }
        <div className="modal-btn">
          <button onClick={setIsVertual.bind(this,false)} className="first-btn">
           Cancel
          </button>
          {
            apiWallet="api" &&
           <button onClick={handleConfirm} className="btn">
            I've made payment
          </button> 
          }
          
        </div>
      </div>
    </div>
    </div>
  );
}

export default VirtualAccount;
