import React from "react";
import "./tables.scss";
import { DotLoader } from "react-spinners";

function TableLoader({ msg }) {
  return (
    <div className="overlay_tbl_loading">
      <div className="tbl_load_content">
        <DotLoader color="#259792" />
        {!msg ? <p>Loading Table Data...</p> : <p>{msg}</p>}
      </div>
    </div>
  );
}

export default TableLoader;
