import React, { useState } from "react";
import './Modal.scss'
import { useSelector } from 'react-redux';
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

function FundBalance({setIsAmount,setIsPaymentMethod,setIsVertual,setIsCardMethod,getLinkedCards,balance,transactionId,setNubanData,setIsComingSoon,payoutCurrency,apiWallet,setApiWallet,setIsApiPayout,amountInput,setNubanTempData,setIsVirtualConfirm,setNubanId,setNubTempInput}) {
//   if (!open) return null;
  const [selectedOption, setSelectedOption] = useState('');
  // const { currentUser } = useSelector((state) => state?.user);
  let currentUser = JSON.parse(localStorage.getItem("portco"));
  const [isLoading,setIsLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handlePayout = (e)=>{
    
    e.preventDefault();
    setIsComingSoon(true);
  }

  const handleApiWallet = (e)=>{
    e.preventDefault();
    
    setIsApiPayout(true)
  }

  const handleCard = ()=>{
   
    setIsCardMethod(true);
  }
  
  const handleVirtualNuban = async ()=>{
    setIsLoading(true)
    let payLoad;
    let url;
    try{

     
      const headers = {  
          Authorization:`Bearer ${currentUser?.user?.merchantKey}`
      }

      if(apiWallet==="api"){
        const id = uuidv4();
        setNubanId(id);
        setNubanId(id)
         payLoad = { 
          transactionId: id,
          nubanType: "temporary",
          amount: amountInput,
          currency_settled:"NGN",
          email:currentUser?.user?.business_email,
          bank:"gtbank",
          is_api_wallet:true
        }
      }else{
         payLoad = { 
            transactionId: transactionId,
            nubanType: "business"
        }
      }
      setNubTempInput(payLoad);
      console.log("nuban payload: ",payLoad)
      if(apiWallet==="api"){
        url ="https://flick-app.com/collection/nuban-create"
        let res = await axios.post(url,payLoad,{
          headers:headers
        });
        setNubanTempData(res.data?.responseData?.data)
        console.log("nuban Data: ",res.data?.responseData?.data);
      }else{
        url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
        let res = await axios.post(url,payLoad,{
          headers:headers
        });
        console.log("nuban Data: ",res.data);
        setNubanData(res.data.data);
      }
      
     
      setIsLoading(false)
      setIsVertual(true)
      
       
      //  console.log(res.data);
        
      //  toast.success(res.data?.message);
      //  const id = res.data?.data?.transactionId
      
  }catch(err){
      setIsLoading(false)
      console.log(err)
      const errorMessage = err.response?.data?.message || "Unable to generate Nuban; try again"
      toast.error(errorMessage);
  }
    
  }

  const callPaymentOption = ()=>{
    if(selectedOption === 'card') return  getLinkedCards();
    if(selectedOption === 'payout') return 
  }
  return (
    <div className="overlay">
    <div className="enter-amount-modal">
      <div className="modal-wrapper">

        <div>
        <img onClick={setIsPaymentMethod.bind(this,false)} style={{cursor:"pointer"}} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">{balance ? "Fund Payout Balance":"Fund API Wallet"}</p>
          <p className="enter-an-amount">Select your payment method</p>
        </div>
        <div className="fund-options">
          <div className={`card-option ${selectedOption === 'payout' ? 'selected' : ''}`} onClick={() => setSelectedOption('payout')}>
              <div className="icon-bank">
              <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
              <div className="transfer-text">
                  <p className="card-payment">Other Balances</p>
                  <p className="card-top-up">Fund your wallet from your payout balance</p>
              </div>
              </div>
              <input 
              type="radio" 
              name="options"
              value="payout"
              checked={selectedOption === 'payout'}
              onChange={handleOptionChange}
              />
          </div>
          {
            payoutCurrency !== "USD" &&
            <>    
              <div className={`card-option ${selectedOption === 'card' ? 'selected' : ''}`} onClick={() => setSelectedOption('card')}>
                  <div className="icon-bank">
                  <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
                  <div className="transfer-text">
                      <p className="card-payment">Card Payment</p>
                      <p className="card-top-up">Top up your balance via your card.</p>
                  </div>
                  </div>
                  <input
                  type="radio" 
                  name="options"
                  value="card"
                  checked={selectedOption === 'card'}
                  onChange={handleOptionChange}
                  />
              </div>
              <div className={`card-option ${selectedOption === 'bank' ? 'selected' : ''}`} onClick={() => setSelectedOption('bank')}>
                  <div className="icon-bank">
                  <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
                  <div className="transfer-text">
                      <p className="card-payment">Bank Transfer</p>
                      <p className="card-top-up">Top up your balance via generated virtual account.</p>
                  </div>
                  </div>
                      <input 
                      type="radio" 
                      name="options"
                      value="bank"
                      checked={selectedOption === 'bank'}
                      onChange={handleOptionChange}
                      />
              </div>
            </>
          }

        
        
        </div>


        <div className="modal-btn">
          <button onClick={() => { setIsAmount(false); setIsPaymentMethod(false); }} className="first-btn">
            Cancel
          </button>
          <button onClick={selectedOption === 'bank' ? handleVirtualNuban:selectedOption === 'card'? handleCard:selectedOption === 'payout'? (apiWallet ==="api"? handleApiWallet:handlePayout):"" } className="btn">
            {
              isLoading ?
              <BeatLoader
                color="#FFF"
                cssOverride={{}}
              />:
              <>
              Confirm
              </>
            }
            
          </button>
          
        </div>
        </div>
    </div>
    </div>
  );
}

export default FundBalance;
