import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';

function PinOtp({ setIsPinOtp,setIsOtp, setIsTransactionSuccess,setIsCardMethod,setIsPaymentMethod,setIsAmount,transactionId,setIsOtpLoading,getBalance}) {
    const [otpInput,setOtpInput] = useState('');
    // const { currentUser } = useSelector((state) => state?.user);
    let currentUser = JSON.parse(localStorage.getItem("portco"));
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = ()=>{
       setIsPinOtp(false)
        setIsOtp(false);
        setIsCardMethod(false);
        setIsPaymentMethod(false);
        setIsAmount(false)
    }
    const handleChange = (e)=>{
        setOtpInput(e.target.value);
    }
    const handleOtp = async ()=>{
      const otpData ={
        transactionId: transactionId,
        otp: otpInput
     }
     setIsOtpLoading(true)
     
     try{
          const headers = {  
            Authorization:`Bearer ${currentUser?.user?.merchantKey}`
          }
         const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/verify-otp"
           
         let res = await axios.post(url,{...otpData},{
           headers:headers
         })
         console.log("otp response",res?.data)
         getBalance();
         setIsOtpLoading(false)
         if(res.data.status==="success" && res.data.authorizationMode ==="pin"){
             setIsLoading(false);
             setIsOtp(true)
         }else if(res.data.status==="success" && res.data.authorizationMode !=="pin" ){
             setIsLoading(false);
             setIsTransactionSuccess(true)
             
            
         }else{
             setIsLoading(false);
             return 
         }
         
       }catch(err){
         setIsLoading(false);
         setIsOtpLoading(false)
         console.log(err)
         const errorMessage = err?.response?.data?.message || "server error"
         toast.error(errorMessage);
       }
    }
  return (
    <div className="overlay"> 
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div>
          <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              style={{cursor:"pointer"}}
              onClick={setIsPinOtp.bind(this,false)}
            />
          </div>

          <div className="modal-title">
            <p className="enter-amount">Enter Transaction OTP</p>

            <p className="enter-an-amount">
            Please input your transaction otp to complete this transaction
            </p>
          </div>

          <div className='pin_container'>
             <input type='password' className='input_otps' onChange={handleChange} placeholder='xxxxxxxx'/>
          </div>
        
          <div className="modal-btn">
            <button  onClick={handleClose} className="first-btn">
              Cancel
            </button>
            <button  onClick={handleOtp} className="btn">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PinOtp
