import React, { useState } from "react";
import "../common/tables.scss";
import ApiWalletBalance from "../overview/ApiWalletBalance";
import TableWalletModal from "../overview/TableWalletModal";
import { toast } from "react-toastify";
import TableLoader from "../common/TableLoader";

function BalanceTable({ balanceData,openSetLimitModal,setIsAmount,setIsModalOpen,setBalanceModal,setIsDollarAmount,setIsTransferOption,setIsNigerBankTransfer,setIsPaymentMethod,isBankLoading,setIsApiWallet,setPayoutCurrency,setApiWallet}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleBalance = (balance) => {
    setBalanceModal(balance);
    if (balance === "NGN") {
      setIsAmount(true);
    } else if (balance === "USD") {
      // setIsDollarAmount(true)
      toast.error("Unavailable feature");
    }
  };

  const handleTransfer = (balance) => {
    setBalanceModal(balance);
    if (balance === "NGN") {
      setIsNigerBankTransfer(true);
    } else if (balance === "USD") {
      // setIsDollarAmount(true)
      toast.error("Unavailable feature");
    }
  };
  const handleApiAmount = (api)=>{
    setPayoutCurrency("")
    setApiWallet(api)
    
    setIsAmount(true);
   }
   const handleDollarAmount = (currency)=>{
    setApiWallet("")
    
    setPayoutCurrency(currency)
    setIsApiWallet(true);
    setIsAmount(true);
   }
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="balance-overview">
      {
            isBankLoading &&
            <TableLoader />
      }
      <table className="tbl">
        <tr>
          <th className="wallet_text">Currency</th>
          <th className="wallet_text">Ledger Bal.</th>
          <th className="wallet_text">Available Bal.</th>
          <th className="wallet_text">Actions</th>
        </tr>

        {Array.isArray(balanceData) &&
          balanceData.length > 0 &&
          balanceData.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="flag">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/images/${item.currency}.svg`
                    }
                    alt="flag"
                  />
                  <h1 className="ng">{item.currency}</h1>
                </div>
              </td>
              <td className="balance-amount">
                {`${item.currency} ${Number(item.collection_balance) / 100}`}{" "}
              </td>
              <td className="balance-amount">{`${item.currency} ${
                Number(item.payout_balance) / 100
              }`}</td>
              <td className="col_width">
                {/* <div className='balance-gap'>
                       <img style={{cursor:"pointer"}} onClick={()=> handleBalance("NGN")} src='/images/fundBalances.svg' alt='stan'/>
                       <img style={{cursor:"pointer",marginLeft:"21px"}} onClick={()=>handleTransfer("NGN")} src='/images/transfers.svg' alt='stan'/>
                </div> */}
                <div className="balance-gap">
                  {item.currency !== "USD" && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleBalance(item.currency)}
                      src="/images/fundBalances.svg"
                      alt="stan"
                    />
                  )}
                  {item.currency !== "USD" && (
                    <img
                      style={{ cursor: "pointer", marginLeft: "21px" }}
                      onClick={() => handleTransfer(item.currency)}
                      src="/images/transfers.svg"
                      alt="stan"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}

        {/* {
            balanceData.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Transactions Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
        } */}

        {/* {
    balanceData.length > 0 &&
          <tr>
                <td>
                    <div className='flag'>
                        <img src={process.env.PUBLIC_URL+`/images/NGN.svg`} alt="flag" />
                        <h1 className='ng'>NGN</h1>
                    </div>
                </td>
                <td className='balance-amount'>NGN 5,000.00</td>
                <td className='balance-amount'>NGN 5,000.00</td>
                <td className='col_width'>
                    <div className='balance-gap'>
                       <img style={{cursor:"pointer"}} onClick={()=> handleBalance("NGN")} src='/images/fundBalances.svg' alt='stan'/>
                       <img style={{cursor:"pointer",marginLeft:"21px"}} onClick={()=>handleTransfer("NGN")} src='/images/transfers.svg' alt='stan'/>
                        
                    </div>
                </td>
            </tr>
    }   */}
        {/*duplicate  */}
        {/* <tr>
                <td>
                    <div className='flag'>
                        <img src={process.env.PUBLIC_URL+`/images/USD.svg`} alt="flag" />
                        <h1 className='ng'>USD</h1>
                    </div>
                </td>
                <td className='balance-amount'>USD 5,000.00</td>
                <td className='balance-amount'>USD 5,000.00</td>
                <td className='col_width'>
                    <div className='balance-gap'>
                       <img style={{cursor:"pointer"}} onClick={()=> handleBalance("USD")} src='/images/fundBalances.svg' alt='stan'/>
                       <img style={{cursor:"pointer",marginLeft:"21px"}} onClick={()=>handleTransfer("USD")} src='/images/transfers.svg' alt='stan'/>
                        
                    </div>
                </td>
            </tr>     */}
      </table>

      {/* <ApiWalletBalance align="center" mt="32px"  amount="N20,000.12" setIsSetLimit={setIsModalOpen} setIsPaymentMethod={setIsPaymentMethod}  /> */}
    </div>
  );
}

export default BalanceTable;
