import JSEncrypt from "jsencrypt";

// const pubk =`-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuBKc965M1U+LTHLLkzzg
// 9zE2q27wqxOXIlGKtdwyzBo8q1MZ8Dr2dN1NJuwM8GTu23kyWveHFs17LsKLiLE/
// iMnfPCKzjTylZd5oIkw8CQ2xkSdZTer7mu237Qy7Nq946UcwgSBCdGuv4OHUIgia
// geb2bjjz3s0CG1wNs8Xv3bUBW+i3SwU/V5bLkn68mP20TUNkHAMILepBTDME1dSb
// TPwyJ6z4cMyooD6ZA5wMntOmsu03Xl69Ms75wAgzTq72yfyfR3nZOijgWFDoLruv
// kD05cf35OslJDUEr/XM/I90jwGPDEMLcXInjVwesMJyDWkXUN9BU0E6BR3ltB1I7
// mwIDAQAB
// -----END PUBLIC KEY-----
// `;
export function encryptWithPublicKey(data, pubk) {
  const key = `${pubk}`;
  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(key);
    const encryptedData = encrypt.encrypt(data);
    return encryptedData;
  } catch (err) {
    console.log(err.message);
    return { error: err.message };
  }
}
