import React, {useState, useEffect} from 'react'
import '../../common/Pages.scss'
import TableHeaderSearch from '../../common/TableHeaderSearch'
import NameControl from '../../common/NameControl'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { getIconByName } from '../../utilities/getBankIcon'

function CustomerAccount() {

  useEffect(()=>{
    getAccounts();
    // eslint-disable-next-line
  },[])
  // const {currentUser} = useSelector((state)=>state?.user)
  let currentUser = JSON.parse(localStorage.getItem("portco"));

  const [isAction,setIsAction] = useState(false);
  const [accountData,setAccountData] = useState([])
  const [isAccountLoading,setIsAccountLoading] = useState(false)
  const ToggleAction = ()=>{
    setIsAction(!isAction);
  }

  const getAccounts = async ()=>{
    setIsAccountLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://flick-app.com/merchant/get-accounts';
      let res = await axios.get(url,{
        headers:headers
      })

      const data = res?.data?.data
      console.log("Account Data: ",data)
      setAccountData(data);
      setIsAccountLoading(false)
      // setBalanceData(newValue || []);
       
    }catch(err){
      setIsAccountLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }

  return (
    <div className='data_customer'>

        {/* <CustomerOverView data customer /> */}
        {/* <CustomerDetails 
        email="mide@flick.app"
        phone="08136902667"
        linked="11"
        date="Sep 5, 2023"
        synced="Sep 5, 2023"
        /> */}
        
      <div className='main-inner'>
              <NameControl
                btn
                name="Accounts"
                desc="All linked Accounts and Balances"
              />
      </div>
        
       <div className='balance-overview'>
          <table className='tbl'>
          <tr>
              <td colSpan="7" className='tbl-heading'>
                  <TableHeaderSearch
                  title="Accounts"
                  placeholder="Search by bank..."
                  numUsers={accountData.length}
                  users={" connected"}
                  btnFirst
                  account
                  />     
              </td>
          </tr>
          
           {
           Array.isArray(accountData) && accountData.length > 0  &&
            <tr>
            
              <th className='th-text'>Account</th>
              <th className='th-text'>Account Name</th>
              <th className='th-text'>Currency</th>
              <th className='th-text'>Total Balance</th>
            </tr>
        }
        {
          Array.isArray(accountData) && accountData.length > 0 && accountData.map((account,index)=>(
            <tr key={account?.bvn}>
              <td>
                <div className='customer-name'>
                    <img className='customer-name-img' src={getIconByName(account?.BankName)} alt="customer" />
                    <h2 className=' td-text'>{account?.BankName || "N/A"} | {account?.Nuban}</h2>
                </div>
            </td> 
            <td className='customer-name-text'>{account?.fullName || "N/A"}</td>
            <td>
                <div className='flag'>
                  {/* <img src={process.env.PUBLIC_URL+"/images/NG.svg"} alt="flag" /> */}
                  <img src={`/images/${ account?.currency === "NGN" ? "NGN" : account?.currency === "USD" ? "USD" : "" }.svg`} alt="usd"/>
                  <h1 className='ng'>{account?.currency || "N/A"}</h1>
                </div>
            </td>
            <td className='td-text'>{account?.Balance/100}</td>
            </tr>
          ))
        }

        {
          !isAccountLoading && accountData.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Account Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
           }
          </table>
        </div>
    </div>
  )
}

export default CustomerAccount
