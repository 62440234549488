import React, { useRef } from "react";
import './Modal.scss'
import { copyToClipboard } from "../utilities/clipboardUtils";

function TransactionSuccessful({setIsTransactionSuccess,setIsPinOtp,amountInput,transactionId,setIsPin,setIsOtp,setIsCardDetails,setIsCardMethod,setIsPaymentMethod,setIsAmount,setIsBalanceOpen,setIsTransferCurrency,setNGNPortfolioAmount,setIsTransferPortfolio,setIsTransferOption,setIsNigerBankTransfer,transferBankInput,payoutResult}) {

const accRef = useRef();

  const handleCancel = ()=>{
    setIsTransactionSuccess(false)
    setIsPinOtp(false)
    setIsOtp(false)
    setIsCardDetails(false)
    setIsCardMethod(false)
    setIsPaymentMethod(false)
    setIsAmount(false)
  }
  return (
    <div className="overlay" >
    <div className="enter-amount-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-wrapper">

        <div>
        <img src={process.env.PUBLIC_URL + "/images/iconSuccess.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Transaction Successful</p>
          
          <p className="enter-an-amount">Your transaction has been initiated, check your balance</p>
        </div>

        {/* <div className="transact-amount">
          <p className="t-amount">Transaction amount</p>
          <p className="amount">{`₦${transferBankInput?.amount}`}</p>
        </div>

        <div className="account-details">
            <div className="number-name">
                <div className="number">
                    <p className="title">Recipient</p>
                    <p className="sub-title">{transferBankInput?.beneficiary_name}</p>
                </div>

                <div className="transact-name">
                    <p className="title">Recipient bank</p>
                    <p className="sub-title">{`${payoutResult?.bank_name || ''}  -  ${transferBankInput?.account_number}`}</p>
                </div>
            </div>

            <div className="number-name">
                <div className="number">
                    <p className="title">Charges</p>
                    <p className="sub-title">0.00</p>
                </div>

                <div className="transact-name">
                    <p className="title">Reference</p>
                    <p className="sub-title">{payoutResult?.Id} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                </div>
            </div>

        </div> */}
        <div className="transact-amount">
          <p className="t-amount">Transaction amount</p>
          <p className="amount">{`₦${amountInput}`}</p>
        </div>

        <div className="account-details">

            <div className="number-name">
             
                <div className="number">
                    <p className="title">Charges</p>
                    <p className="sub-title">0.00</p>
                </div>

                <div className="transact-name">
                  <p className="title">Reference</p>
                <div style={{display:"flex",alignItems:"center"}}>

                    <input 
                    ref={accRef}
                    type='text' 
                    value={transactionId} 
                    className='account-td copyInput'
                    name='account_number'
                    />
                    <img 
                    src='/images/copyBalance.svg' 
                    alt="copy"
                    onClick={copyToClipboard.bind(null,accRef)}
                    />
                </div>
                </div>
            </div>

        </div>

        <div className="modal-btn">
          <button onClick={handleCancel} className="first-btn">
            Cancel
          </button>
          <button className="btn">
            Download Receipt
          </button>
        </div>
        </div>
    </div>
    </div>
  );
}

export default TransactionSuccessful;
