import React, { useState } from "react";
import "./Modal.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { BeatLoader } from "react-spinners";


function SelectPayment({setIsCardMethod,setIsOtp, setIsCardDetails,LinkedCardData,transactionId,setIsPinOtp,apiWallet}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [card, setCard] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const { currentUser } = useSelector((state) => state?.user);
  let currentUser = JSON.parse(localStorage.getItem("portco"));


  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectedCard = (card)=>{
    setSelectedOption(card?.cardId &&  (card?.cardType).toLowerCase())
    setCard(card)
  }
  const handleCard = async  ()=>{
    if(!selectedOption) 
    return toast.warning("Select a card or add a new card")

    setIsLoading(true)
    let cardData;
    try{
      
      const headers = {  
        Authorization:`Bearer ${currentUser?.user?.merchantKey}`
      }
      if(apiWallet==="api"){
        cardData = {
          cardDetails: card?.cardDetails,
          transactionId: transactionId,
          dest:"apiWallet"
        }
      }else{

        cardData = {
          cardDetails: card?.cardDetails,
          transactionId: transactionId,
        }
      }

      console.log("payload for card: ",cardData)
      const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/charge"
      
      let res = await axios.post(url,{...cardData},{
        headers:headers
      }) ;
      
      console.log("card response",res?.data)
      const {authorizationMode} = res.data
      
      setIsLoading(false);

      if(authorizationMode==="pin"){
        setIsOtp(true)
         console.log("condition pin: ",res.data)
      }
      else if(authorizationMode === "otp"){
        setIsPinOtp(true);
        console.log("otp",res.data)
      }
      else if((authorizationMode).toLowerCase() === "3ds"){
        if((res.data.authMessage).toUpperCase() === "3DS URL" && res.data?.responseUrl){  
          setIsLoading(false);
          const url = res.data.responseUrl;
          window.location.href = url;
          console.log(res?.data);
          
        }else if((res.data.authMessage).toLowerCase()==="3ds" && !res.data.responseUr){
          if(authorizationMode === "otp"){
            setIsPinOtp(false); 
          }
          setIsLoading(false);
          toast.error("unable to complete payment");
        }else{
          setIsLoading(false);
          toast.error("unable to complete payement");
        } 
      }
      else{
        console.log("condition not met ")
      }
    }catch(err){
      console.log(err)
      setIsLoading(false);
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage)
   }


  }

  return (
    <div className="overlay">
      <div className="enter-amount-modal">
        <div className="modal-wrapper ">
          <div>
          <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              style={{cursor:"pointer"}}
              onClick={setIsCardMethod.bind(this,false)}
            />
          </div>

          <div className="modal-title">
            <p className="enter-amount">Select Payment Method</p>

            <p className="enter-an-amount">
              Please choose the desired payment card for your transaction.
            </p>
          </div>
          <div className="card_bar">

            {
              Array.isArray(LinkedCardData) && LinkedCardData.length > 0 && LinkedCardData.map((card,index)=>(
                <div key={card?.cardId} className={`master-option ${selectedOption === (card?.cardId) ? 'selected' : ''}`} onClick={() => handleSelectedCard(card)}>
                  <div className="icon-option">
                    <img
                      src={process.env.PUBLIC_URL + `/images/${card?.cardType==="VISA"?"visa-card" : card?.cardType ==="MASTERCARD" && "masterCard"}.svg`}
                      alt="icon"
                    />
                    <div className="master-text">
                      <p className="master-payment">{`${card?.cardType} ending in ${card?.cardLastFour}`}</p>
                      <p className="master-expiry">Expiry 06/2024</p>
                      <p className="set-default-two">
                        Set as default{" "}
                        <span  className="edit">
                          Edit
                        </span>
                      </p>
                    </div>
                  </div>
                  <input 
                      type="radio" 
                      name="options"
                      value={(card?.cardType).toLowerCase()}
                      checked={selectedOption === (card?.cardType).toLowerCase()}
                      onChange={handleOptionChange}
                      />
                </div>
              ))
        
            }

          </div>

          <div className="add-payment" style={{cursor:"pointer"}}>
            <img
              src={process.env.PUBLIC_URL + "/images/modal-plus.svg"}
              alt="icon"
            />
            <p className="add-text" onClick={setIsCardDetails.bind(this,true)}>Add new payment card</p>
          </div>

          <div className="modal-btn">
            <button  onClick={setIsCardMethod.bind(this,false)} className="first-btn">
              Cancel
            </button>
             {
               isLoading ? 
               <button   className="btn">  
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
               </button> :
                <button  onClick={handleCard} className="btn">
                  Confirm
                </button>

            }
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectPayment;

















// import React, { useState } from "react";
// import "./Modal.scss";


// function SelectPayment({setIsCardMethod,setIsPaymentMethod,setIsAmount,setIsOtp, setIsCardDetails,setIsBalanceOpen,setIsPin}) {
//   const [selectedOption, setSelectedOption] = useState('');
  
//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   const handleCancel = ()=>{
//     setIsCardMethod(false)
//     setIsPaymentMethod(false)
//     setIsAmount(false)
//     setIsBalanceOpen(false)
//   }

//   return (
//     <div className="overlay">
//       <div className="enter-amount-modal">
//         <div className="modal-wrapper">
//           <div>
//           <img
//               src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
//               alt="icon"
//               style={{cursor:"pointer"}}
//               onClick={setIsCardMethod.bind(this,false)}
//             />
//           </div>

//           <div className="modal-title">
//             <p className="enter-amount">Select Payment Method</p>

//             <p className="enter-an-amount">
//               Please choose the desired payment card for your transaction.
//             </p>
//           </div>

//           <div className={`master-option ${selectedOption === 'visa' ? 'selected' : ''}`} onClick={() => setSelectedOption('visa')}>
//             <div className="icon-option">
//               <img
//                 src={process.env.PUBLIC_URL + "/images/visa-card.svg"}
//                 alt="icon"
//               />
//               <div className="master-text">
//                 <p className="master-payment">Visa ending in 1234</p>
//                 <p className="master-expiry">Expiry 06/2024</p>
//                 <p className="set-default-two">
//                   Set as default{" "}
//                   <span  className="edit">
//                     Edit
//                   </span>
//                 </p>
//               </div>
//             </div>
//             <input 
//                 type="radio" 
//                 name="options"
//                 value="visa"
//                 checked={selectedOption === 'visa'}
//                 onChange={handleOptionChange}
//                 />
//           </div>

//           <div className={`master-option ${selectedOption === 'master' ? 'selected' : ''}`} onClick={() => setSelectedOption('master')}>
//             <div className="icon-option">
//               <img
//                 src={process.env.PUBLIC_URL + "/images/masterCard.svg"}
//                 alt="icon"
//               />
//               <div className="master-text">
//                 <p className="master-payment">Mastercard ending in 1234</p>
//                 <p className="master-expiry">Expiry 06/2024</p>
//                 <p className="set-default-two">
//                   Set as default{" "}
//                   <span  className="edit">
//                     Edit
//                   </span>
//                 </p>
//               </div>
//             </div>
//             <input 
//             type="radio" 
//             name="options"
//             value="master"
//             checked={selectedOption === 'master'}
//             onChange={handleOptionChange}
//             />
//           </div>

//           <div className="add-payment" style={{cursor:"pointer"}}>
//             <img
//               src={process.env.PUBLIC_URL + "/images/modal-plus.svg"}
//               alt="icon"
//             />
//             <p className="add-text" onClick={setIsCardDetails.bind(this,true)}>Add new payment card</p>
//           </div>

//           <div className="modal-btn">
//             <button  onClick={handleCancel} className="first-btn">
//               Cancel
//             </button>
//             <button  onClick={selectedOption === 'visa' ? setIsOtp.bind(this,true):selectedOption === 'master'? setIsOtp.bind(this,true):"" } className="btn">
//               Confirm
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SelectPayment;
