import React, { useState, useEffect } from "react";
import NameControl from "../components/common/NameControl";
import BalanceTable from "../components/balance/BalanceTable";
import BalanceHistoryTable from "../components/balance/BalanceHistoryTable";
import SetLimit from "../components/modals/SetLimit";
import BankAccountModal from "../components/balance/modal/BankAccountModal";
import BalanceModal from "../components/balance/modal/BalanceModal";
import CurrencyModal from "../components/balance/modal/CurrencyModal";
import EnterAmount from "../components/modals/EnterAmount";
import FundBalance from "../components/modals/FundBalance";
import VirtualAccount from "../components/modals/VirtualAccount";
import CardDetails from "../components/modals/CardDetails";
import SelectPayment from "../components/modals/SelectPayment";
import TransactionOtp from "../components/modals/TransactionOtp";
import TransactionSuccessful from "../components/modals/TransactionSuccessful";
import Success from "../components/modals/Success";
import Successful from "../components/modals/Successful";
import ApiWalletConfirm from "../components/modals/ApiWalletConfirm";
import ApiWallet from "../components/modals/ApiWallet";
import DollarAmount from "../components/customers/modals/DollarAmount";
import DollarVirtual from "../components/customers/modals/DollarVirtual";
import DollarSuccess from "../components/customers/modals/DollarSuccess";
import TransferOptions from "../components/customers/modals/TransferOptions";
import TransferBetweenCurrency from "../components/customers/modals/TransferBetweenCurrency";
import TransferToBank from "../components/customers/modals/TransferToBank";
import Beneficiary from "../components/customers/modals/Beneficiary";
import BeneficiaryValue from "../components/customers/modals/BeneficiaryValue";
import TransferPortfolio from "../components/customers/modals/TransferPortfolio";
import PortfolioCompanyAmount from "../components/customers/modals/PortfolioCompanyAmount";
import NigerTransferPortfolioAmount from "../components/customers/modals/NigerTransferPortfolioAmount";
import PortFolioConfirm from "../components/customers/modals/PortFolioConfirm";
import PinOtp from "../components/modals/PinOtp";
import axios from "axios";
import TableLoader from "../components/common/TableLoader";
import ComingSoonPop from "../components/modals/ComingSoonPop";
import ApiPayoutBalance from "../components/modals/ApiPayoutBalance";
import VirtualConfirm from "../components/modals/VirtualConfirm";
import ApiWalletOtp from "../components/modals/ApiWalletOtp";
import PortfolioSuccess from "../components/customers/modals/PortfolioSuccess";

function Balance() {
  useEffect(() => {
    getBalance();
    getBanks();
    getEncKey();

    // getCollection();
    // getSettlement();
    // getGraph();
    // getWalletapi();
    // eslint-disable-next-line
  }, []);
  const [balanceData, setBalanceData] = useState([]);

  let currentUser = JSON.parse(localStorage.getItem("portco"));

  const [btnActive, setBtnActive] = useState(false);
  let [selectedButton, setSelectedButton] = useState("All Balances");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isAmount, setIsAmount] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isVertual, setIsVertual] = useState(false);
  const [isCardDetails, setIsCardDetails] = useState(false);
  const [isCardMethod, setIsCardMethod] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isApiWalletConfirm, setIsApiWalletConfirm] = useState(false);
  const [isPayout, setIsPayout] = useState(false);
  const [payoutId, setPayoutId] = useState("");
  const [payoutResult, setPayoutResult] = useState({});
  const [banks, setBank] = useState([]);
  const [payoutToken,setToken] = useState('');
  // eslint-disable-next-line
  const [balanceModal, setBalanceModal] = useState({});
  const [isDollarAmount, setIsDollarAmount] = useState(false);
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [isDollarVirtual, setIsDollarVirtual] = useState(false);
  const [isTransferPortfolioAmount, setIsTransferPortfolioAmount] =
    useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [isNigerBankTransfer, setIsNigerBankTransfer] = useState(false);
  const [isSelectBeneficiary, setIsSelectBeneficiary] = useState(false);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isDollarSuccess, setIsDollarSuccess] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [isPinOtp, setIsPinOtp] = useState(false);
  const [isCardsLoading, setIsCardLoading] = useState(false);
  const [isTransferCurrency, setIsTransferCurrency] = useState(false);
  // const [balanceData,setBalanceData] = useState([])
  const [otpInput, setOtpInput] = useState("");
  const [isNGNPortfolioAmount, setNGNPortfolioAmount] = useState(false);
  const [isTransferOption, setIsTransferOption] = useState(false);
  const [isTransferPortfolio, setIsTransferPortfolio] = useState(false);
  const [isTransferBank, setIsTransferBank] = useState(false);
  const [isPinLoading, setIsPinLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [payoutCurrency, setPayoutCurrency] = useState("");
  const [beneficiary, setBeneficiary] = useState(false);
  const [amountInput, setAmountInput] = useState("");
  const [apiWallet, setApiWallet] = useState("");
  const [isAmountLoading, SetIsAmountLoading] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [nubanTempData, setNubanTempData] = useState({});
  const [isApiPayout, setIsApiPayout] = useState(false);
  const [isApiWallet, setIsApiWallet] = useState(false);
  const [isBankLoading,setIsBankLoading] = useState(false)
  const [isApiFundLoading, setIsApiFundLoading] = useState(false);
  const [isApiOtp, setIsApiOtp] = useState(false);
  const [apiData, setApiData] = useState({});
  const [apiInputData, setApiInputData] = useState({});
  const [nubanData, setNubanData] = useState([]);
  const [nubanId, setNubanId] = useState("");
  const [nubTemInput, setNubTempInput] = useState({});
  const [isVirtualConfirm, setIsVirtualConfirm] = useState(false);
  const [LinkedCardData, setLinkedCardData] = useState([]);
  const [transactionId, setTransactionid] = useState("");
  const [livePublicEncKey, setLivePublicEncKey] = useState("");
  const [inputCardDetails, setInputCardDetails] = useState({
    name: "",
    card_number: "",
    card_date: "",
    card_cvv: "",
  });

  const [isPayoutInput, setIsPayoutInput] = useState({
    currency: "",
    amount: "",
  });
  const [isPortfolioInput, setIsPortfolioInput] = useState({
    currency: "",
    amount: "",
    amount2: "",
    desc: "",
  });
  const [transferBankInput, setTransferBankInput] = useState({
    balance: "",
    amount: "",
    currency: "NGN",
    bank_code: "",
    narration: "",
    account_number: "",
    beneficiary_name: "",
    bank_name: ""
  });
  const [formData, setFormData] = useState({
    selectedBalance: "",
    selectedCurrency: "USD",
    amount: "",
    description: "",
  });
  const [newCurrency, setNewCurrency] = useState({
    currencyName: "",
    limitAmount: "",
  });

  // eslint-disable-next-line
  const [fundBalanceValues, setFundBalanceValues] = useState({
    amount: "",
    bank: "",
    card: "",
  });
  const [limitInputValue, setLimitInputValue] = useState("");
  const toggleModal = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  //Balance
  const getBalance = async () => {
    setIsBankLoading(true)

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/balances?category=collection",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso',
          // 'Authorization': 'Bearer pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso'
          api_key: currentUser.user.merchantKey,
          Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
        },
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("balance", newValue);
      setBalanceData(newValue);
      setIsBankLoading(false)

    } catch (error) {
      setIsBankLoading(false)
      console.log(error);
    }
  };

  const getBanks = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/merchant/banks",
        headers: {
          // 'api_key': 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso'
          api_key: currentUser?.user?.merchantKey,
        },
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const newValue = response?.data?.data;
      console.log("bank", newValue);
      setBank(newValue);
    } catch (err) {
      console.log(err);
      const errorMessage = err?.message || "unable to fetch data";
    }
  };

  const iniateCharge = async (e) => {
    let amount;
    SetIsAmountLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
      };

      amount = {
        amount: `${amountInput * 100}`,
        url: "https://checkout.paywithflick.co/pages/",
      };
      console.log("amount Payload", amount);
      const url =
        "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge";
      let res = await axios.post(url, amount, {
        headers: headers,
      });

      const transId = res?.data?.data?.transactionId || res.data?.transactionId;
      setTransactionid(transId);
      SetIsAmountLoading(false);
      setIsPaymentMethod(true);
      //  console.log(res.data);

      //  toast.success(res.data?.message);
      //  const id = res.data?.data?.transactionId
    } catch (err) {
      SetIsAmountLoading(false);
      console.log(err);
    }
  };

  const getEncKey = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-encryption-key";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data?.livePublicEncKey;
      setLivePublicEncKey(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getLinkedCards = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-payment-card";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data;
      setLinkedCardData(data);
    } catch (err) {
      console.log(err);
    }
  };

  //  const getBalance = async ()=>{

  //     try{

  //       const headers = {
  //         "api_key": currentUser.user.merchantKey,
  //         "sweetapi": currentUser.user.merchantCode
  //       }
  //       // const url = 'https://1esi7ghq4i.execute-api.us-east-2.amazonaws.com/production/balances';
  //       const url = 'https://flick-app.com/merchant/balances?category=collection';
  //       let res = await axios.get(url,{
  //         headers:headers
  //       })

  //       const newValue = res.data.data
  //       console.log("balance: ",newValue)
  //      setBalanceData(newValue || []);

  //     }catch(err){
  //       console.log(err)
  //       const errorMessage = err?.message || "unable to fetch data"

  //     }
  //   }

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const toggleSetLimitModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const Transferbetween = () => {
    console.log("from parent: ", formData);
  };
  const AddCurrency = () => {
    console.log("currency: ", newCurrency);
  };
  const confirmLimit = () => {
    console.log("limit value : ", limitInputValue);
  };
  const renderContent = () => {
    if (selectedButton === "All Balances")
      return (
        <BalanceTable
          openSetLimitModal={toggleSetLimitModal}
          setIsAmount={setIsAmount}
          setIsDollarAmount={setIsDollarAmount}
          setIsModalOpen={setIsModalOpen}
          setIsPaymentMethod={setIsPaymentMethod}
          setBalanceModal={setBalanceModal}
          setIsTransferOption={setIsTransferOption}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsComingSoon={setIsComingSoon}
          setIsApiWallet={setIsApiWallet}
          balanceData={balanceData}
          setApiWallet={setApiWallet}
          setPayoutCurrency={setPayoutCurrency}
          isBankLoading={isBankLoading}
        />
      );
    if (selectedButton === "Balance History") return <BalanceHistoryTable />;
  };

  const renderModal = () => {
    switch (selectedOption) {
      case "Transfer to Bank Account":
        return <BankAccountModal setIsOpenPopup={setIsOpenPopup} />;
      case "Transfer between Balances":
        return (
          <BalanceModal
            setIsOpenPopup={setIsOpenPopup}
            Transferbetween={Transferbetween}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case "Add New Currency":
        return (
          <CurrencyModal
            setIsOpenPopup={setIsOpenPopup}
            newCurrency={newCurrency}
            setNewCurrency={setNewCurrency}
            AddCurrency={AddCurrency}
          />
        );
      default:
        return null; // No modal to display
    }
  };

  // FundApi wallet via other Balance

  const handleApiOtherBalance = async (currency) => {
    setIsApiFundLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${currentUser?.user?.merchantKey}`,
      };
      const data = {
        amount: `${amountInput * 100}`,
        currency: currency,
        bank_code: "apiwallet",
      };

      // Make an API call to fetch accountName using bankCode and accountNumber.
      console.log("api payLoad:", data);
      setApiInputData(data);
      const url =
        "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
      let res = await axios.post(url, { ...data }, { headers: headers });
      console.log("response Data: ", res.data);
      setApiData(res.data);
      setIsApiFundLoading(false);
      setIsApiOtp(true);

      // setIsLoading(false)
    } catch (err) {
      setIsApiFundLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="main-inner">
      <NameControl
        currency
        name={selectedButton}
        desc="The balance is shown here"
        btnFirst="All Balances"
        btnSecond="Balance History"
        btnActive={btnActive}
        handleButtonClick={handleButtonClick}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
        toggleModal={toggleModal}
      />
      {renderContent()}

      {isModalOpen && (
        <SetLimit
          open={true}
          onClose={toggleSetLimitModal}
          title="Set low limit"
          desc="Enter a minimum threshold to be notified"
          setIsModalOpen={setIsModalOpen}
          setLimitInputValue={setLimitInputValue}
          limitInputValue={limitInputValue}
          confirmLimit={confirmLimit}
          setIsSuccessful={setIsSuccessful}
        />
      )}
      {isSuccessful && (
        <Successful
          setIsSuccessful={setIsSuccessful}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isOpenPopup && renderModal()}

      {isDollarAmount && (
        <DollarAmount
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsDollarVirtual={setIsDollarVirtual}
        />
      )}
      {isAmount && (
        <EnterAmount
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          iniateCharge={iniateCharge}
          isAmountLoading={isAmountLoading}
          setAmountInput={setAmountInput}
          isApiWallet = {isApiWallet}
          setApiWallet={setApiWallet}
        />
      )}

      {isDollarVirtual && (
        <DollarVirtual
          setIsDollarVirtual={setIsDollarVirtual}
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsDollarSuccess={setIsDollarSuccess}
        />
      )}

      {isDollarSuccess && (
        <DollarSuccess
          setIsDollarSuccess={setIsDollarSuccess}
          setIsDollarVirtual={setIsDollarVirtual}
          setIsDollarAmount={setIsDollarAmount}
          setIsBalanceOpen={setIsBalanceOpen}
        />
      )}

      {isTransferOption && (
        <TransferOptions
          setIsTransferOption={setIsTransferOption}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransferBank={setIsTransferBank}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
        />
      )}

      {isTransferCurrency && (
        <TransferBetweenCurrency
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          isPortfolioInput={isPortfolioInput}
        />
      )}
      {isNigerBankTransfer && (
        <TransferToBank
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsTransferOption={setIsTransferOption}
          transferBankInput={transferBankInput}
          setTransferBankInput={setTransferBankInput}
          setIsSelectBeneficiary={setIsSelectBeneficiary}
          setIsBeneficiary={setIsBeneficiary}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          // banks={banks}
          payoutId={payoutId}
          balanceData={balanceData}
          setPayoutId={setPayoutId}
          setPayoutResult={setPayoutResult}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          beneficiary={beneficiary}
        />
      )}
      {isSelectBeneficiary && (
        <Beneficiary setIsSelectBeneficiary={setIsSelectBeneficiary} />
      )}
      {isBeneficiary && (
        <BeneficiaryValue
          setIsBeneficiary={setIsBeneficiary}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setBeneficiary={setBeneficiary}
        />
      )}
      {isTransferPortfolio && (
        <TransferPortfolio
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          balanceModal={balanceModal}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
        />
      )}
      {isTransferPortfolioAmount && (
        <PortfolioCompanyAmount
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          setIsBeneficiary={setIsBeneficiary}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          isPortfolioInput={isPortfolioInput}
        />
      )}
      {isNGNPortfolioAmount && (
        <NigerTransferPortfolioAmount
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsPortfolioInput={setIsPortfolioInput}
          setIsBeneficiary={setIsBeneficiary}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          isPortfolioInput={isPortfolioInput}
        />
      )}

      {isConfirmPortfolio && (
        <PortFolioConfirm
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsBeneficiary={setIsBeneficiary}
          setIsOpenPopup={setIsOpenPopup}
          setToken={setToken}
          payoutId={payoutId}
          payoutToken={payoutToken}
          transferBankInput={transferBankInput}
          payoutResult ={payoutResult}
        />
      )}

      {isPortfolioSuccess && (
        <PortfolioSuccess
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsBalanceOpen={setIsBalanceOpen}
          transferBankInput={transferBankInput}
          payoutResult={payoutResult}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsOpenPopup={setIsOpenPopup}
        
        />
      )}
      {isSuccessful && (
        <Successful
          setIsSuccessful={setIsSuccessful}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {isPaymentMethod && (
        <FundBalance
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsVertual={setIsVertual}
          setIsCardMethod={setIsCardMethod}
          setIsPayout={setIsPayout}
          getLinkedCards={getLinkedCards}
          transactionId={transactionId}
          setNubanData={setNubanData}
          setIsComingSoon={setIsComingSoon}
          payoutCurrency={payoutCurrency}
          apiWallet={apiWallet}
          setApiWallet={setApiWallet}
          setIsApiPayout={setIsApiPayout}
          amountInput={amountInput}
          setNubanTempData={setNubanTempData}
          setIsVirtualConfirm={setIsVirtualConfirm}
          setNubanId={setNubanId}
          setNubTempInput={setNubTempInput}
        />
      )}
      {isVertual && (
        <VirtualAccount
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsVertual={setIsVertual}
          setIsSuccess={setIsSuccess}
          transactionId={transactionId}
          nubanData={nubanData}
          apiWallet={apiWallet}
          nubanTempData={nubanTempData}
          setIsVirtualConfirm={setIsVirtualConfirm}
          amountInput={amountInput}
        />
      )}
      {isVirtualConfirm && (
        <VirtualConfirm
          setIsVirtualConfirm={setIsVirtualConfirm}
          isVirtualConfirm={isVirtualConfirm}
          transactionId={nubanId}
          nubTemInput={nubTemInput}
          getBalance={getBalance}
        />
      )}
      {isApiPayout && (
        <ApiPayoutBalance
          setIsApiPayout={setIsApiPayout}
          balanceData={balanceData}
          handleApiOtherBalance={handleApiOtherBalance}
          isApiFundLoading={isApiFundLoading}
        />
      )}
      {isCardMethod && (
        <SelectPayment
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsCardMethod={setIsCardMethod}
          setIsPin={setIsPin}
          setIsOtp={setIsOtp}
          setIsCardDetails={setIsCardDetails}
          setIsBalanceOpen={setIsBalanceOpen}
           LinkedCardData ={LinkedCardData}
           setIsPinOtp={setIsPinOtp}
           transactionId={transactionId}
           apiWallet = {apiWallet}
        />
      )}
      {isPayout && (
        <ApiWallet
          setIsPayout={setIsPayout}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayoutInput={setIsPayoutInput}
          setIsAmount={setIsAmount}
          isPayoutInput={isPayoutInput}
          setIsApiWalletConfirm={setIsApiWalletConfirm}
        />
      )}
      {isApiWalletConfirm && (
        <ApiWalletConfirm
          setIsApiWalletConfirm={setIsApiWalletConfirm}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayout={setIsPayout}
          setIsAmount={setIsAmount}
          setIsSuccess={setIsSuccess}
        />
      )}
      {isApiOtp && (
        <ApiWalletOtp
          setIsApiOtp={setIsApiOtp}
          setIsApiPayout={setIsApiPayout}
          apiData={apiData}
          apiInputData={apiInputData}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          getBalance={getBalance}
        />
      )}
      {isCardDetails && (
        <CardDetails
          setIsCardDetails={setIsCardDetails}
          setIsCardMethod={setIsCardMethod}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          transactionId={transactionId}
          livePublicEncKey={livePublicEncKey}
          setIsOtp={setIsOtp}
          setIsPinOtp={setIsPinOtp}
          inputCardDetails={inputCardDetails}
          setInputCardDetails={setInputCardDetails}
          setIsCardLoading={setIsCardLoading}
          apiWallet={apiWallet}
        />
      )}
      {isOtp && (
        <TransactionOtp
          setIsOtp={setIsOtp}
          setIsPin={setIsPin}
          setIsCardMethod={setIsCardMethod}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsAmount={setIsAmount}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsSuccess={setIsSuccess}
          setIsPinLoading={setIsPinLoading}
          transactionId={transactionId}
          setIsPinOtp={setIsPinOtp}
        />
      )}
      {isPinOtp && (
        <PinOtp
          setIsPinOtp={setIsPinOtp}
          setIsOtpLoading={setIsOtpLoading}
          setIsOtp={setIsOtp}
          setIsTransactionSuccess={setIsTransactionSuccess}
          transactionId={transactionId}
          getBalance={getBalance}
        />
      )}
      {isTransactionSuccess && (
        <TransactionSuccessful
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsPin={setIsPin}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsCardMethod={setIsCardMethod}
          setIsCardDetails={setIsCardDetails}
          setIsOtp={setIsOtp}
          setIsPinOtp={setIsPinOtp}
          setIsAmount={setIsAmount}
          transferBankInput={transferBankInput}
          payoutResult={payoutResult}
          setIsTransferCurrency={setIsTransferCurrency}
          setIsBalanceOpen={setIsBalanceOpen}
          setNGNPortfolioAmount={setNGNPortfolioAmount}
          setIsTransferPortfolio={setIsTransferPortfolio}
          setIsTransferOption={setIsTransferOption}
          setIsNigerBankTransfer={setIsNigerBankTransfer}
          inputCardDetails={inputCardDetails}
          amountInput={amountInput}
          transactionId={transactionId}
          getBalance={getBalance}
        />
      )}
      {isSuccess && (
        <Success
          setIsAmount={setIsAmount}
          setIsPaymentMethod={setIsPaymentMethod}
          setIsPayout={setIsPayout}
          setIsApiWalletConfirm={setIsApiWalletConfirm}
          setIsCardMethod={setIsCardMethod}
          setIsCardDetails={setIsCardDetails}
          setIsOtp={setIsOtp}
          setIsVertual={setIsVertual}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsSuccess={setIsSuccess}
          setIsBalanceOpen={setIsBalanceOpen}
        />
      )}
      {isComingSoon && <ComingSoonPop setIsComingSoon={setIsComingSoon} />}

      {(isAmountLoading || isCardsLoading || isOtpLoading || isPinLoading) && (
        <TableLoader msg="Processing..." />
      )}
    </div>
  );
}

export default Balance;
