import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners';
import '../components/verify/verify.scss'
function VerifyUser() {
    const navigate = useNavigate()
    useEffect(()=>{
        authUser();
    // eslint-disable-next-line
    }, [])

    const authUser = async ()=>{
        const fullUrl = window.location.href;
        const urlParts = fullUrl.split('/');
        const token = urlParts[urlParts.length - 1];
        const url = "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/user"
        try{
            const res = await axios.post(url,{token})
            // const data =JSON.stringify(res.data);
            // localStorage.setItem("portco",data);

            if(res.data){
                const data =JSON.stringify(res.data);
                localStorage.setItem("portco",data);
                console.log("log", data)
                return navigate('/')
            } else{
                const externalPageUrl = "https://merchant.getflick.co/";
                window.location.href = externalPageUrl

                
                // return navigate('/')
            }
                
        }catch(err){
            console.log(err)
            toast.error("error occured")
            // const externalPageUrl = "https://merchant.getflick.co/";
            // window.location.href = externalPageUrl
            
                // added by dipo remove below and uncomment when ready for redirect //
                return navigate('/')

        }
       
    }
  return (
    <div className='verify_overlay'>
     <div className='overlay_showcase'></div>
     <div className='overlay_containers'>
        <FadeLoader 
        color='#F7FCFC' 
        />
        <p>verifying...</p>
     </div>
    </div>
  )
}

export default VerifyUser
